export const GET_ORDERS = "GET_ORDERS";
export const SET_LOADER = "SET_LOADER";
export const SET_LOADER_REFRESH = "SET_LOADER_REFRESH";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const POST_PICK_TOUR = "POST_PICK_TOUR";
export const STORE_ACTIVE_PICK_TOUR = "STORE_ACTIVE_PICK_TOUR";
export const POST_UPDATE_PICK_TOUR = "POST_UPDATE_PICK_TOUR";
export const STORE_SKIPPED_PICK_TOUR = "STORE_SKIPPED_PICK_TOUR";
export const REMOVE_SKIPPED_ITEM = "REMOVE_SKIPPED_ITEM";
export const REQUEST_STORE_ID_SUCCESS = "REQUEST_STORE_ID_SUCCESS";
export const STORE_SELECTED_PRODUCTS = "STORE_SELECTED_PRODUCTS";
export const SET_SERVICE_UN_AVAILABLE = "SET_SERVICE_UN_AVAILABLE";
export const RE_SET_SERVICE_UN_AVAILABLE = "RE_SET_SERVICE_UN_AVAILABLE";
export const USER_LOGOUT = "USER_LOGOUT";
export const REMOVE_STORE_DATA = "REMOVE_STORE_DATA";
export const SKIPPED_FLAG = "SKIPPED_FLAG";
export const RESTART_PICK_TOUR = "RESTART_PICK_TOUR";
export const CLEAR_SKIPPED_ITEMS = "CLEAR_PICKED_ITEMS";
export const IS_PICK_TOUR_IN_PROGRESS = "IS_PICK_TOUR_IN_PROGRESS";
export const IS_FETCHING_EMPLOYEE_DATA = "IS_FETCHING_EMPLOYEE_DATA";
export const IS_FETCHING_TOKENINFO = "IS_FETCHING_TOKENINFO";
export const STORE_SKIP_COMPLETEDLIST = "STORE_SKIP_COMPLETEDLIST";
export const STORE_SKIPPED_ITEMS_STORE_DETAILS = "STORE_SKIPPED_ITEMS_STORE_DETAILS";
export const UPDATE_STEP = "UPDATE_STEP";
export const MANUAL_STORE_UPDATE = "MANUAL_STORE_UPDATE";
export const UPDATE_IS_SAVE_PICK_TOUR = "UPDATE_IS_SAVE_PICK_TOUR";
export const CLEAR_SERVICE_ERROR_MESSAGES = "CLEAR_SERVICE_ERROR_MESSAGES";
export const E_POST_PICK_TOUR = "E_POST_PICK_TOUR";
export const E_REFRESH_GET_ORDERS = "E_REFRESH_GET_ORDERS";
export const E_COMPLETE_ITEM = "E_COMPLETE_ITEM";
export const E_PART_SUBMIT_AND_COMPLETE = "E_PART_SUBMIT_AND_COMPLETE";
export const E_SKIP_ITEM = "E_SKIP_ITEM";
export const E_PRINT = "E_PRINT";
export const E_FORWARD = "E_FORWARD";
export const E_CONTINUE = "E_CONTINUE";
export const E_GET_ORDERS = "E_GET_ORDERS";
export const API_IN_PROGRESS = "API_IN_PROGRESS";
export const CLEAR_ALL_ERROR_MESSAGES = "CLEAR_ALL_ERROR_MESSAGES";
