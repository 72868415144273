import { auth } from "../components/AuthContext";
import { store, persistor } from "../store";
import * as types from "./actionTypes";
const ENV = window.env;

export let newSignIn;

// signout api call
export const clearSession = async (error) => {
  if (error === "signout") {
    try {
      await auth.revoke();
    } catch (error) {
      // TODO: log error?
    } finally {
      sessionStorage.clear();
      store.dispatch({
        type: types.REMOVE_STORE_DATA
      });
      localStorage.clear();

      persistor.flush().then(() => {
        persistor.purge().then(() => {
          window.location = ENV.HOST_URL;
        });
      });
    }
  }
};
