import React, { useEffect, useRef } from "react";

const TextInputScanner = ({ onScan }) => {
  const hiddenInputRef = useRef(null);

  const onKeyDown = (event) => {
    event.preventDefault();
    hiddenInputRef.current.focus();
  };

  const onKeyUp = (event) => {
    event.preventDefault();
    hiddenInputRef.current.blur();
  };

  const onChange = (event) => {
    const value = event.target.value;
    if (onScan && value.length > 1) {
      onScan(value);
    }
  };

  /**
   * We need document event listeners here due to how the type of scanner that utilizes this component works.
   * This particular type of scanner requires a focused input in order to trigger an event to grab the scan data.
   * Adding document event listeners here that focus/blur an input on scanner trigger press/release allows the scanner
   * to be used anywhere this component is used without the user needing to focus on a specific element. The event
   * listeners are removed from the document when this component unmounts in order to save memory.
   */

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  return (
    <input
      ref={hiddenInputRef}
      value=""
      style={{ position: "absolute", top: -100 }}
      tabIndex={-1}
      onChange={onChange}
    />
  );
};

export default TextInputScanner;
