import React from "react";
/* custom HOC for heading */
const Header = (props) => {
  const { size, text, css } = props;
  switch (size) {
    case 1:
      return <h1 className={css}>{text}</h1>;
    case 2:
      return <h2 className={css}>{text}</h2>;
    case 3:
      return <h3 className={css}>{text}</h3>;
    case 4:
      return <h4 className={css}>{text}</h4>;
    case 5:
      return <h5 className={css}>{text}</h5>;
    case 6:
      return <h6 className={css}>{text}</h6>;
    default:
      return <h6 className={css}>{text}</h6>;
  }
};

export default Header;
