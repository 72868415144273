import React, { Component } from "react";
import { Button } from "react-bootstrap";
import LoadingIndicator from "../../components/LoadingIndicator";

export default class OButton extends Component {
  onButtonClick() {
    // call the props as events for custom button component
    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }
  }
  /* apply styling based on the type of the button primary/link/secondary */
  getClass(customClass, isPrimaryLink, type) {
    let _class;
    if (customClass) {
      _class = customClass;
    } else {
      _class = `btnWrapper ${isPrimaryLink === true && type === "link" ? "underlined" : ""}`;
    }
    return _class;
  }
  render() {
    const { type, size, text, id, isPrimaryLink, customClass, loading = false } = this.props;
    let _disabled = this.props.disabled ? this.props.disabled : false;
    return (
      <span id={id} className={this.getClass(customClass, isPrimaryLink, type)}>
        <Button
          bsStyle={type}
          bsSize={size}
          disabled={_disabled}
          onClick={this.onButtonClick.bind(this)}
          data-testid={this.props["data-testid"]}
          style={{ position: "relative" }}
        >
          {loading && <LoadingIndicator />}
          <span style={{ color: loading ? "transparent" : "inherit" }}>{text}</span>
        </Button>
      </span>
    );
  }
}
