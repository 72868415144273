import React, { Component } from "react";
import Dialog from "react-toolbox/lib/dialog/Dialog";
class ADialog extends Component {
  handleToggle() {
    /* close/open the modal based on the event flag */
    if (this.props.handleToggle) this.props.handleToggle();
  }
  render() {
    const { modalProps, id } = this.props;
    return (
      <div>
        <Dialog
          id={id}
          active={modalProps.active}
          onEscKeyDown={this.handleToggle.bind(this)}
          onOverlayClick={() => {}}
          title={modalProps.title}
          className="dialogMainWrapper"
        >
          {modalProps.bodyText}
        </Dialog>
      </div>
    );
  }
}

export default ADialog;
