import React, { Component, createRef } from "react";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { size } from "lodash";
import OButton from "../core-libs/components/Button";
import ADialog from "../core-libs/components/Dialog";
import ErrorMessage from "../core-libs/components/ErrorMessage";
const closeIcon = require("../assets/images/close-x.svg");

const initialState = {
  manualStoreNumber: "",
  confirmationStoreNumber: "",
  isFirstEntry: true,
  isFirstInstance: true,
  hasMismatchedStoreNumberError: false,
  loading: false
};

const reinitializedState = {
  ...initialState,
  isFirstInstance: false
};

const deviceInfo = navigator.userAgent;
const isTC75 = deviceInfo.includes("TC75");

export default class ManualStoreNumber extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.textFieldRef = createRef();
  }
  componentDidUpdate(prevProps) {
    if (this.props.modalState && !prevProps.modalState) {
      this.setState(this.state.isFirstInstance ? initialState : reinitializedState);
    } else if (!this.props.modalState && prevProps.modalState && this.state.isFirstInstance) {
      this.setState({
        isFirstInstance: false
      });
    }
  }
  handleClose() {
    this.props.onCloseManualStore();
  }

  constructedProps() {
    const { modalState, employeeStore, onManualStoreSubmit } = this.props;
    const {
      manualStoreNumber,
      confirmationStoreNumber,
      isFirstEntry,
      isFirstInstance,
      hasMismatchedStoreNumberError
    } = this.state;
    const isMetaStoreVerification = employeeStore && isFirstInstance;
    const REG_EX_IGNITION = /^\d*$/;
    const determineModalTitle = () => {
      if (employeeStore) {
        if (isFirstInstance) {
          return "Verify Store";
        }
        return "Enter Store Number";
      }
      return "Choose A Store";
    };
    const determineModalMessage = () => {
      if (isMetaStoreVerification) {
        return `Do you want to pick at ${employeeStore.replace(/^0+/, "")}?`;
      }
      if (isFirstEntry) {
        return "Please enter store number to pick orders.";
      }
      return `You entered store ${manualStoreNumber.replace(
        /^0+/,
        ""
      )}, please enter again to confirm.`;
    };
    const handleSubmit = async () => {
      if (isMetaStoreVerification) {
        try {
          this.setState({
            loading: true
          });
          await onManualStoreSubmit(employeeStore);
        } finally {
          this.setState({
            loading: false
          });
        }
      } else if (isFirstEntry) {
        this.setState({
          isFirstEntry: false,
          hasMismatchedStoreNumberError: false
        });
        this.textFieldRef.current.focus();
      } else if (
        manualStoreNumber.replace(/^0+/, "") !== confirmationStoreNumber.replace(/^0+/, "")
      ) {
        this.setState({
          ...reinitializedState,
          hasMismatchedStoreNumberError: true
        });
      } else {
        try {
          this.setState({
            loading: true
          });
          await onManualStoreSubmit(manualStoreNumber.padStart(8, "0"));
        } finally {
          this.setState({
            loading: false
          });
        }
      }
    };
    return {
      active: modalState,
      title: "",
      bodyText: (
        <div id="manualStoreDialog">
          <div className="manualTitle">
            <div className="manualText">{determineModalTitle()}</div>
            <div className="closeBtn">
              <span className="closeImg" onClick={() => this.handleClose()}>
                <img alt="closeIcon" src={closeIcon} />
              </span>
            </div>
          </div>
          <div className="manualBody">
            {hasMismatchedStoreNumberError && (
              <ErrorMessage message=" - Entered store numbers did not match" />
            )}
            <div id="bodyLine1">
              <div id="bodyLabel">{determineModalMessage()}</div>
            </div>
            {!isMetaStoreVerification && (
              <div id="bodyLine2">
                <TextField
                  autoFocus
                  id="storeValue"
                  label="Store #"
                  type="number"
                  autoComplete="off"
                  margin="normal"
                  variant="outlined"
                  inputRef={this.textFieldRef}
                  value={isFirstEntry ? manualStoreNumber : confirmationStoreNumber}
                  onChange={(e) =>
                    size(e.target.value) <= 8 &&
                    REG_EX_IGNITION.test(e.target.value) &&
                    this.setState({
                      [isFirstEntry ? "manualStoreNumber" : "confirmationStoreNumber"]: e.target
                        .value
                    })
                  }
                  onKeyUp={(e) => {
                    if (e.keyCode === 13 || (e.keyCode === 9 && isTC75)) {
                      handleSubmit();
                    }
                  }}
                />
              </div>
            )}
          </div>
          <Divider />
          <div className="manualActionsWrapper">
            <OButton
              id="submitButton"
              text={isMetaStoreVerification ? "Yes" : "Submit"}
              customClass="btnSubmit"
              data-testid="manual-store-number-submit"
              loading={this.state.loading}
              disabled={
                this.state.loading ||
                (!isMetaStoreVerification &&
                  (isFirstEntry ? manualStoreNumber : confirmationStoreNumber) === "")
              }
              onButtonClick={() => handleSubmit()}
            />
            <OButton
              id="cancelBtn"
              text={isMetaStoreVerification ? "No" : "Cancel"}
              customClass="btnCancel"
              data-testid="manual-store-number-cancel"
              onButtonClick={() =>
                isMetaStoreVerification
                  ? this.setState({ isFirstInstance: false })
                  : this.handleClose()
              }
            />
          </div>
        </div>
      )
    };
  }
  render() {
    return <ADialog modalProps={this.constructedProps()} handleToggle={() => this.handleClose()} />;
  }
}
