import * as types from "../constants/actionTypes";
let defaultState = {
  isStartPickTourError: false,
  isGetOrdersRefreshError: false,
  isCompleteItemError: false,
  isPartSubmitError: false,
  isSkipItemError: false,
  isPrintError: false,
  isForwardError: false,
  isContinueError: false,
  isGetOrdersError: false
};

export const errorsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.E_GET_ORDERS: {
      return Object.assign({}, state, { isGetOrdersError: action.flag });
    }
    case types.E_POST_PICK_TOUR: {
      return Object.assign({}, state, { isStartPickTourError: action.flag });
    }
    case types.E_REFRESH_GET_ORDERS: {
      return Object.assign({}, state, { isGetOrdersRefreshError: action.flag });
    }
    case types.E_COMPLETE_ITEM: {
      return Object.assign({}, state, { isCompleteItemError: action.flag });
    }
    case types.E_PART_SUBMIT_AND_COMPLETE: {
      return Object.assign({}, state, { isPartSubmitError: action.flag });
    }
    case types.E_SKIP_ITEM: {
      return Object.assign({}, state, { isSkipItemError: action.flag });
    }
    case types.E_PRINT: {
      return Object.assign({}, state, { isPrintError: action.flag });
    }
    case types.E_FORWARD: {
      return Object.assign({}, state, { isForwardError: action.flag });
    }
    case types.E_CONTINUE: {
      return Object.assign({}, state, { isContinueError: action.flag });
    }
    case types.CLEAR_SERVICE_ERROR_MESSAGES: {
      return Object.assign({}, state, defaultState);
    }
    case types.CLEAR_ALL_ERROR_MESSAGES: {
      return Object.assign({}, state, {
        isStartPickTourError: false,
        isGetOrdersRefreshError: false,
        isCompleteItemError: false,
        isPartSubmitError: false,
        isSkipItemError: false,
        isPrintError: false,
        isForwardError: false,
        isContinueError: false,
        isGetOrdersError: false
      });
    }
    default:
      return state;
  }
};
