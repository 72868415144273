import React, { Component, Fragment } from "react";
import OButton from "../core-libs/components/Button";
import ErrorMessage from "../core-libs/components/ErrorMessage";
class CompletePick extends Component {
  state = {
    loading: false
  };

  onHandleCompleted = async () => {
    try {
      this.setState({
        loading: true
      });
      await this.props.handleCompleted();
    } finally {
      this.setState({ loading: false });
    }
  };
  componentDidMount() {
    localStorage.setItem("isPickTourStarted", "false");
  }
  render() {
    let { errors, isSat2Sat } = this.props;
    return (
      <div id="completePick">
        <div className="wrapper">
          {isSat2Sat ? (
            <Fragment>
              <span className="invoicesPrinted">Sat to Sat Transfer Label(s) Printed.</span>
              <span className="invoicesPrintedNoPadding">Please stage for hub driver pickup.</span>
            </Fragment>
          ) : (
            <span className="invoicesPrinted">
              Orders Being Finalized. Check Printer For Invoices
            </span>
          )}
          {errors && errors.isPrintError && (
            <div id="errorWrapperCompletedPick">
              <ErrorMessage message="printing" />
            </div>
          )}
          {errors && errors.isGetOrdersError && (
            <div id="errorWrapperCompletedPick">
              <ErrorMessage message="submitting" />
            </div>
          )}
          <div>
            <span className="confirm">
              <OButton
                onButtonClick={this.onHandleCompleted}
                type="primary"
                id="btnCompleteItem"
                data-testid="active-view-complete-item"
                text="RETURN TO PENDING ORDERS"
                size="sm"
                customClass="confirmOk"
                loading={this.state.loading}
              />
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default CompletePick;
