import React from "react";
import Button from "../core-libs/components/Button";
const noInternet = require("../assets/images/offline-icon36x32.svg");
const tryagain = require("../assets/images/refresh-icon-30x30.svg");
const NoInterNet = (props) => {
  return (
    <div id="noInternet" data-testid="no-internet-container">
      <div className="wrapper wrap-center noInternetCr">
        <img
          alt="noInternet"
          className="noInternetIcon"
          src={noInternet}
          width="36px"
          height="32px"
        />
        <h4>You&apos;re Offline</h4>
        <div>Check your connection and try again</div>
      </div>
      <div className="wrapper wrap-center tryAgainCr">
        <img alt="tryAgain" src={tryagain} width="30px" height="30px" />
        <Button
          onButtonClick={() => {
            if (props.setResetServiceUnavialbility) {
              props.setResetServiceUnavialbility(null);
            }
            if (props.getOrders) {
              if (props.header === props.HEADER_CONST.PENDING_ORDERS) {
                props.getOrders();
              } else {
                window.location.reload();
              }
            }
          }}
          id="linkTryAgain"
          data-testid="no-internet-try-again"
          type="link"
          size="sm"
          text="Try Again"
          isPrimaryLink={true}
        />
      </div>
    </div>
  );
};

export default NoInterNet;
