import React, { useEffect } from "react";
import OButton from "../core-libs/components/Button";
import eventLogger from "../utils/eventLogger";

export default function ErrorFallback(props) {
  const { error, resetErrorBoundary } = props;

  useEffect(() => {
    eventLogger.errorWithMetadata(error.message, JSON.stringify({ stack: error.stack }), "crash");
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <p style={{ fontSize: 18 }}>Something is stuck in the gears. Please try again.</p>
      <OButton text="Try Again" type="primary" size="lg" onButtonClick={resetErrorBoundary} />
    </div>
  );
}
