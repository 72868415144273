import React, { Component, Fragment } from "react";
/* Core Library */
import { Row, Col } from "react-bootstrap";
import MediaQuery from "react-responsive";
import {
  find,
  flattenDepth,
  size,
  sortBy,
  some,
  isEmpty,
  trim,
  filter,
  assign,
  remove,
  get,
  each,
  toArray,
  groupBy,
  sumBy,
  orderBy
} from "lodash";
import { Link } from "@material-ui/core";
import Button from "../core-libs/components/Button";
import ErrorMessage from "../core-libs/components/ErrorMessage";
import Header from "../core-libs/components/Header";
import ListLayout from "../core-libs/components/ListLayout";

/* Used for different layouts for Desktop and Mobile view */

/* Active Orders Component */
import eventLogger from "../utils/eventLogger";
import ActiveViewOrders from "./ActiveViewOrders";
import NoInterNet from "./NoInterNet";
// skip Item Validation component
import SkipItemValidation from "./SkipItemValidation";
import CompletedPick from "./CompletedPick";
import ItemScanningRequired from "./ItemScanningRequired";
import CustomerNoteToStore from "./CustomerNoteToStore";
const refresh = require("../assets/images/refresh-icon-30x30.svg");
const TRANSFER = "TRANSFER";
/* constructPickTourList: group the selected orders by there partNumberId */
const constructPickTourList = (selectedProducts, data) => {
  /* Get all the details of the orders based on the sequence number and productId */
  let _selectedPickTours =
    selectedProducts &&
    selectedProducts.map((item) => {
      return find(
        data.orders,
        (startPick) =>
          startPick.commercialDeliveryGroupSequenceNB === item.sequenceNB &&
          startPick.commercialSalesOrderUID === item.pId
      );
    });
  const _deliveryGroupDetails =
    _selectedPickTours && _selectedPickTours.map((item) => item.deliveryGroupDetails);
  /* Flatten them to single array */
  const _pickTourList = flattenDepth(_deliveryGroupDetails, 1);
  return _pickTourList;
};
const HEADERS = {
  ACTIVE_PICKTOUR: "Active Pick Tour",
  SKIP_ITEM_VALIDATION: "Skipped Item Validation",
  PENDING_ORDERS: "Pending Orders",
  COMPLETED_PICKTOUR: "Pick Completed"
};
export const VIEWS = {
  DESKTOP: "Desktop",
  MOBILE: "Mobile"
};
export default class Ordering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isItemScanningRequired: false,
      selectedProducts:
        props.activePickTour && size(props.activePickTour) > 0 ? props.activePickTour : [],
      selectedPickTour:
        props.activePickTour && size(props.activePickTour) > 0 ? props.activePickTour : [],
      activePickTourUID: props.activePickTourUID ? props.activePickTourUID : null,
      skipItemValidation: props.skippedPickTours && size(props.skippedPickTours) > 0,
      completePick: false,
      confirmClicked: false,
      foundAll: false,
      isPickTourSelected: false,
      isOrdersSelected: false,
      isCustomerNote: false,
      noteObject: null,
      loading: false
    };
  }
  sortByLocations(selectedItems, createPickTour) {
    const isSat2Sat =
      createPickTour &&
      createPickTour.pickTourList &&
      createPickTour.pickTourList.some((pick) => pick.pickListTypeCD === "SAT2SAT");
    const _selectedItems =
      selectedItems &&
      selectedItems.map((item, index) => {
        const createPickTourLocationObject =
          createPickTour &&
          createPickTour.pickTourList &&
          find(createPickTour.pickTourList, (o) =>
            isSat2Sat
              ? o.replenishmentOrderHeaderUID === item.pId
              : o.partNumberID === item.partNumberID
          );
        if (createPickTourLocationObject) {
          return Object.assign({}, item, { location: createPickTourLocationObject.location });
        } else {
          return Object.assign({}, item, { location: [] });
        }
      });
    const _sortByLocation = sortBy(_selectedItems, (s) => {
      return s.location[0] && s.location[0].vdlSequenceNumber;
    });
    return _sortByLocation;
  }
  /* On Start Pick */
  async onStartPickLogic() {
    if ((this.props.activePickTour || []).length === 0) {
      if (this.state.selectedPickTour && this.state.selectedPickTour.pickTourUID) {
        this.props.restartPickTour(this.state.selectedPickTour);
        let pickTour = this.state.selectedPickTour;
        /* Final list which contains the orders with Urgent, Forward, VDP etc. */
        const { _finalList, _totalCount } = this.getPickTourList(
          this.state.selectedProducts,
          pickTour.pickTourList
        );
        /* To store i redux for the selected orders */
        try {
          this.setState({
            loading: true
          });
          await this.props.postPickTourUrgent(pickTour);
          const { createPickTour } = this.props;
          const _sortList = this.sortByLocations(_finalList, createPickTour);
          // Flag to maintain the wizard steps, false - In Pending Orders, true - In Active/Skip/Completed Pick tours
          localStorage.setItem("isPickTourStarted", true);
          this.props.constructActivePickTour(
            _sortList,
            _totalCount,
            this.state.selectedPickTour.pickTourUID
          );
        } catch (_error) {
          this.setState({
            selectedPickTour: [],
            selectedProducts: [],
            isOrdersSelected: false
          });
        } finally {
          this.setState({ loading: false, isPickTourSelected: false, selectedPickTour: [] });
        }
      } else {
        try {
          this.setState({
            loading: true
          });
          // Pass to parent to call /create
          await this.props.startPickTour(this.state.selectedProducts);
          const { _finalList, _totalCount } = this.getPickTourList(
            this.state.selectedProducts,
            null
          );
          const { createPickTour } = this.props;
          const _sortList = this.sortByLocations(_finalList, createPickTour);
          // Flag to maintain the wizard steps, false - In Pending Orders, true - In Active/Skip/Completed Pick tours
          localStorage.setItem("isPickTourStarted", true);
          this.props.constructActivePickTour(_sortList, _totalCount, null, true);
        } catch (_error) {
          this.setState({
            selectedPickTour: [],
            selectedProducts: [],
            isOrdersSelected: false
          });
        } finally {
          this.setState({ loading: false, isOrdersSelected: false });
        }
      }
    }
    this.setState({
      isItemScanningRequired: false
    });
    this.props.skippedFlag(false);
  }
  /* On start pick the below function will be triggered */
  async onActiveStartPick() {
    let { selectedProducts, selectedPickTour } = this.state;
    const isSatToSatInSelectedProducts = (selectedProducts || []).some(
      (product) =>
        product.deliveryGroupInfo &&
        product.deliveryGroupInfo.some((dgInfo) => dgInfo.deliveryGroupDetailTypeCD === "SAT2SAT")
    );
    const isSatToSatInSelectedPickTour =
      selectedPickTour &&
      selectedPickTour.pickTourList &&
      selectedPickTour.pickTourList.some((item) => item.itemFulfillmentMethodCD === "SAT2SAT");

    const isSat2Sat = isSatToSatInSelectedProducts || isSatToSatInSelectedPickTour;

    // If the type of "Transfer order" call /update api and dont navigate to the Active/Comple/Skip Screens
    if (
      !some(selectedProducts, ["deliveryMethodCD", TRANSFER]) &&
      selectedPickTour.deliveryMethodCD !== TRANSFER &&
      !isSat2Sat
    ) {
      // If UPC barcode scanning required alert the user with modal and message
      const isUPCValidationScanRequiredFLExist = some(selectedProducts, [
        "isUPCValidationScanRequiredFL",
        "1"
      ]);
      const isUPCValidationScanRequiredFLExistCancelled =
        selectedPickTour && selectedPickTour.isUPCValidationScanRequiredFL === "1";
      if (isUPCValidationScanRequiredFLExist || isUPCValidationScanRequiredFLExistCancelled) {
        this.setState({ isItemScanningRequired: true });
      } else {
        const notes = selectedPickTour && selectedPickTour.deliveryGroupInfo;
        const isNoteExistPickTour = size(notes) > 0;
        const isNoteExistProducts = some(
          selectedProducts,
          (o) =>
            o.customerShopNoteLTX &&
            o.customerShopNoteLTX !== " " &&
            !isEmpty(trim(o.customerShopNoteLTX)) &&
            trim(o.customerShopNoteLTX).length > 0
        );
        if (isNoteExistPickTour || isNoteExistProducts) {
          if (selectedPickTour && size(selectedProducts) === 0) {
            const noteArray =
              notes &&
              filter(
                notes,
                (o) =>
                  o.customerShopNoteLTX &&
                  o.customerShopNoteLTX !== " " &&
                  !isEmpty(trim(o.customerShopNoteLTX)) &&
                  trim(o.customerShopNoteLTX).length > 0
              ).map((item) => {
                return {
                  customerShopNoteLTX: item.customerShopNoteLTX,
                  customerOrganizationLNM: item.customerOrganizationLNM,
                  deliverToCustomerCommercialID: item.deliveryToCustomerCommercialID
                };
              });
            if (size(noteArray) > 0) {
              this.setState({
                isCustomerNote: true,
                noteObject: noteArray
              });
            } else {
              this.onStartPickLogic();
            }
          } else if (size(selectedProducts) > 0) {
            const _notes = filter(selectedProducts, (o) => size(trim(o.customerShopNoteLTX)) > 0);
            const noteArray =
              _notes &&
              filter(
                _notes,
                (o) =>
                  o.customerShopNoteLTX &&
                  o.customerShopNoteLTX !== " " &&
                  !isEmpty(trim(o.customerShopNoteLTX)) &&
                  trim(o.customerShopNoteLTX).length > 0
              ).map((firstNote) => {
                return {
                  customerShopNoteLTX: firstNote.customerShopNoteLTX,
                  customerOrganizationLNM: firstNote.customerOrganizationLNM,
                  deliverToCustomerCommercialID: firstNote.deliverToCustomerCommercialID
                };
              });
            if (size(noteArray) > 0) {
              this.setState({
                isCustomerNote: true,
                noteObject: noteArray
              });
            } else {
              this.onStartPickLogic();
            }
          }
        } else {
          // If barcode scanning not required go to active page
          this.onStartPickLogic();
        }
      }
    } else if (isSat2Sat) {
      this.onStartPickLogic();
    } else {
      // if no orders are available nearby store alert the user by message
      this.props.onContinueStore();
    }
  }
  /* Once the user click cancel for scanning required process reset the selected pick tours and navigate back to pending orders screen */
  onResetItemScanningRequired(value) {
    if (value) {
      let { selectedProducts, selectedPickTour } = this.state;
      this.setState({ isItemScanningRequired: value }, () => {
        const notes =
          selectedPickTour &&
          selectedPickTour.customerShopNoteLTX &&
          selectedPickTour.customerShopNoteLTX !== " " &&
          !isEmpty(trim(selectedPickTour.customerShopNoteLTX));
        const isNoteExistPickTour = size(notes) > 0;
        const isNoteExistProducts = some(
          selectedProducts,
          (o) =>
            o.customerShopNoteLTX &&
            o.customerShopNoteLTX !== " " &&
            !isEmpty(trim(o.customerShopNoteLTX)) &&
            trim(o.customerShopNoteLTX).length > 0
        );
        if (isNoteExistPickTour || isNoteExistProducts) {
          if (selectedPickTour && size(selectedProducts) === 0 && isNoteExistPickTour) {
            this.setState({
              isCustomerNote: true,
              noteObject: [
                {
                  customerShopNoteLTX: selectedPickTour.customerShopNoteLTX,
                  customerOrganizationLNM: selectedPickTour.customerOrganizationLNM,
                  deliverToCustomerCommercialID: selectedPickTour.deliverToCustomerCommercialID
                }
              ]
            });
          } else if (size(selectedProducts) > 0 && isNoteExistProducts) {
            const _notes = filter(selectedProducts, (o) => size(trim(o.customerShopNoteLTX)) > 0);
            const noteArray =
              _notes &&
              filter(
                _notes,
                (o) =>
                  o.customerShopNoteLTX &&
                  o.customerShopNoteLTX !== " " &&
                  !isEmpty(trim(o.customerShopNoteLTX)) &&
                  trim(o.customerShopNoteLTX).length > 0
              ).map((firstNote) => {
                return {
                  customerShopNoteLTX: firstNote.customerShopNoteLTX,
                  customerOrganizationLNM: firstNote.customerOrganizationLNM,
                  deliverToCustomerCommercialID: firstNote.deliverToCustomerCommercialID
                };
              });
            this.setState({
              isCustomerNote: true,
              noteObject: noteArray
            });
          }
        } else {
          this.onStartPickLogic();
        }
      });
    } else {
      this.setState({
        isItemScanningRequired: value,
        selectedProducts: [],
        selectedPickTour: {},
        isPickTourSelected: false
      });
    }
  }
  /* Call paretn function to call /Create */
  onSelectedPickTour(pickTour) {
    let _selectedPickTour = assign([], this.state.selectedPickTour);
    const { isOrdersSelected } = this.state;
    if (isOrdersSelected) {
      return false;
    }
    if (_selectedPickTour && _selectedPickTour.pickTourUID) {
      this.setState({ isPickTourSelected: false, selectedPickTour: {} }, () => {
        this.props.onSelectedPickTour(0, null);
      });
    } else {
      this.setState({ isPickTourSelected: true, selectedPickTour: pickTour }, () => {
        this.props.onSelectedPickTour(1, null);
      });
    }
  }

  /* Push the selected ordering items to selected Products array */
  onSelectedProducts(
    pId,
    sequenceNB,
    isUPCValidationScanRequiredFL,
    deliveryMethodCD,
    customerShopNoteLTX,
    customerOrganizationLNM,
    deliverToCustomerCommercialID,
    deliveryGroupInfo
  ) {
    let _selectedProducts = assign([], this.state.selectedProducts);
    const { isPickTourSelected } = this.state;
    if (isPickTourSelected) {
      return false;
    }
    /* If the item exist in the selected Products array remove them else add the new item */
    if (some(_selectedProducts, (item) => item.pId === pId && item.sequenceNB === sequenceNB)) {
      remove(_selectedProducts, (item) => item.pId === pId && item.sequenceNB === sequenceNB);
      if (_selectedProducts.length === 0) {
        this.setState({ isOrdersSelected: false });
      }
    } else {
      const selectedDGDetailType = get(deliveryGroupInfo, "[0].deliveryGroupDetailTypeCD");
      const currentDGDetailType =
        get(_selectedProducts, "[0].deliveryGroupInfo[0].deliveryGroupDetailTypeCD") || "";
      if (
        !_selectedProducts.some(
          (product) =>
            product.deliveryMethodCD === TRANSFER &&
            get(product, "deliveryGroupInfo[0].deliveryGroupDetailTypeCD") !== "SAT2SAT"
        ) &&
        (!currentDGDetailType || selectedDGDetailType === currentDGDetailType)
      ) {
        _selectedProducts.push({
          pId,
          sequenceNB,
          isUPCValidationScanRequiredFL,
          deliveryMethodCD,
          customerShopNoteLTX,
          customerOrganizationLNM,
          deliverToCustomerCommercialID,
          deliveryGroupInfo
        });
        this.setState({ isOrdersSelected: true });
      }
    }
    this.setState({ selectedProducts: _selectedProducts }, () => {
      this.props.onSelectedPickTour(size(_selectedProducts), _selectedProducts);
    });
  }

  onSelectAll() {
    /* On Select All link */
    const { data } = this.props;
    const { selectedProducts } = this.state;
    if (size(selectedProducts) !== size(data.orders)) {
      let _selectedProducts =
        data &&
        data.orders.map((product) => {
          return {
            invoiceStoreID: product.invoiceStoreID,
            keepQuantityOnHand: product.keepQuantityOnHand,
            itemID: product.itemID,
            pId: product.commercialSalesOrderUID,
            sequenceNB: product.commercialDeliveryGroupSequenceNB,
            isUPCValidationScanRequiredFL: product.isUPCValidationScanRequiredFL,
            deliveryMethodCD: product.deliveryMethodCD
          };
        });
      this.setState({ selectedProducts: _selectedProducts });
    } else {
      this.setState({ selectedProducts: [] });
    }
  }
  /* Call parent function to trigger /update - API */
  onUpdatePickTour(pickTour, orderPickedQty, isLastRecord, isFromSkip = false, isSat2Sat = false) {
    const { selectedProducts, selectedPickTour } = this.state;
    if (this.props.onUpdatePickTour) {
      return this.props.onUpdatePickTour(
        pickTour,
        orderPickedQty,
        selectedProducts,
        selectedPickTour,
        isLastRecord,
        isFromSkip,
        isSat2Sat
      );
    }
  }

  getPickTourList(selectedProducts, pickTourList) {
    const { data } = this.props;
    const _constructPickTourList =
      pickTourList != null ? pickTourList : constructPickTourList(selectedProducts, data);
    each(pickTourList, (value) => {
      value.itemDescription = value.itemDS;
    });
    /* Group by partNumberId */
    const _groupPickList = _constructPickTourList.some(
      (item) => item.pickListTypeCD === "SAT2SAT" || item.itemFulfillmentMethodCD === "SAT2SAT"
    )
      ? _constructPickTourList.map((item) => [item])
      : toArray(groupBy(_constructPickTourList, "partNumberID"));
    const _sortedPickList = _groupPickList.map((item) => {
      if (size(item) > 1) {
        let {
          itemDescription,
          partNumberID,
          commercialSalesOrderUID,
          commercialDeliveryGroupSequenceNB,
          isUPCValidationScanRequiredFL,
          itemID,
          invoiceStoreID,
          keepQuantityOnHand,
          replenishmentOrderHeaderUID,
          replenishmentOrderLineNB,
          pickListTypeCD
        } = item[0];
        let orderItemQY = sumBy(item, function (startPick) {
          return startPick.orderItemQY;
        });
        return [
          {
            itemDescription,
            partNumberID,
            orderItemQY,
            pId:
              pickListTypeCD === "SAT2SAT" ? replenishmentOrderHeaderUID : commercialSalesOrderUID,
            sequenceNB:
              pickListTypeCD === "SAT2SAT"
                ? replenishmentOrderLineNB
                : commercialDeliveryGroupSequenceNB,
            isUPCValidationScanRequiredFL,
            itemID,
            invoiceStoreID,
            keepQuantityOnHand
          }
        ];
      } else {
        let {
          itemDescription,
          partNumberID,
          orderItemQY,
          commercialSalesOrderUID,
          commercialDeliveryGroupSequenceNB,
          isUPCValidationScanRequiredFL,
          itemID,
          invoiceStoreID,
          keepQuantityOnHand,
          replenishmentOrderHeaderUID,
          replenishmentOrderLineNB,
          pickListTypeCD
        } = item[0];
        return [
          {
            itemDescription,
            partNumberID,
            orderItemQY,
            pId:
              pickListTypeCD === "SAT2SAT" ? replenishmentOrderHeaderUID : commercialSalesOrderUID,
            sequenceNB:
              pickListTypeCD === "SAT2SAT"
                ? replenishmentOrderLineNB
                : commercialDeliveryGroupSequenceNB,
            isUPCValidationScanRequiredFL,
            itemID,
            invoiceStoreID,
            keepQuantityOnHand
          }
        ];
      }
    });
    /* Sort by order vdlSequenceNumber */
    let _finalList = orderBy(flattenDepth(_sortedPickList, 1), ["vdlSequenceNumber"], ["asc"]);
    const _totalCount = size(_sortedPickList);
    return { _finalList, _totalCount };
  }
  /* Construct orders for skip pick tour */
  getPickTourListForSkipValidation(skippedPickTours) {
    let skippedPickTourList = [];
    skippedPickTours.forEach((item) => {
      item.pickTourList.forEach((itemList) => {
        skippedPickTourList.push(itemList);
      });
    });
    const _groupPickList = skippedPickTourList.some((item) => item.pickListTypeCD === "SAT2SAT")
      ? skippedPickTourList.map((item) => [item])
      : toArray(groupBy(skippedPickTourList, "partNumberID"));
    const _sortedPickList = _groupPickList.map((item) => {
      if (size(item) > 1) {
        let {
          itemDS,
          partNumberID,
          commercialSalesOrderUID,
          commercialDeliveryGroupSequenceNB,
          itemID,
          invoiceStoreID,
          keepQuantityOnHand,
          replenishmentOrderHeaderUID,
          replenishmentOrderLineNB,
          pickListTypeCD
        } = item[0];
        let orderItemQY = sumBy(item, function (skip) {
          return skip.orderItemQY;
        });
        let pickedItemQY = sumBy(item, function (skip) {
          return skip.pickedItemQY;
        });
        let skippedItemQY = orderItemQY - pickedItemQY;
        return [
          {
            itemDS,
            partNumberID,
            pId:
              pickListTypeCD === "SAT2SAT" ? replenishmentOrderHeaderUID : commercialSalesOrderUID,
            sequenceNB:
              pickListTypeCD === "SAT2SAT"
                ? replenishmentOrderLineNB
                : commercialDeliveryGroupSequenceNB,
            orderItemQY,
            skippedItemQY,
            itemID,
            invoiceStoreID,
            keepQuantityOnHand,
            pickListTypeCD
          }
        ];
      } else {
        let {
          itemDS,
          partNumberID,
          orderItemQY,
          commercialSalesOrderUID,
          commercialDeliveryGroupSequenceNB,
          pickedItemQY,
          itemID,
          invoiceStoreID,
          keepQuantityOnHand,
          replenishmentOrderHeaderUID,
          replenishmentOrderLineNB,
          pickListTypeCD
        } = item[0];
        let skippedItemQY = orderItemQY - pickedItemQY;
        return [
          {
            itemDS,
            partNumberID,
            orderItemQY,
            pId:
              pickListTypeCD === "SAT2SAT" ? replenishmentOrderHeaderUID : commercialSalesOrderUID,
            sequenceNB:
              pickListTypeCD === "SAT2SAT"
                ? replenishmentOrderLineNB
                : commercialDeliveryGroupSequenceNB,
            skippedItemQY,
            itemID,
            invoiceStoreID,
            keepQuantityOnHand,
            pickListTypeCD
          }
        ];
      }
    });
    let _finalList = orderBy(flattenDepth(_sortedPickList, 1), ["vdlSequenceNumber"], ["asc"]);
    const _totalCount = size(_sortedPickList);
    return { _finalList, _totalCount };
  }
  /* Call parent function to call /skip - API */
  handleSkipValidation() {
    const { activePickTour, skippedPickTours } = this.props;
    if (activePickTour.length - 1 <= 0 && skippedPickTours.length === 0) {
      this.setState((preState) => {
        return { ...preState, completePick: true };
      });
    } else if (activePickTour.length - 1 <= 0) {
      this.setState((preState) => {
        return {
          ...preState,
          skipItemValidation: true,
          completePick: false
        };
      });
    }
  }
  /* Reset the process flags */
  handleCompletedPage(foundAll) {
    const { skippedPickTours } = this.props;
    if (size(skippedPickTours) - 1 === 0) {
      this.setState((preState) => {
        return {
          ...preState,
          completePick: true,
          confirmClicked: true,
          foundAll
        };
      });
    }
  }
  /* navigate back to pending orders screen by calling get Orders */
  handleCompleted() {
    // Mark the pick tour started flag as false
    localStorage.setItem("isPickTourStarted", false);
    return this.props.getOrders(true);
  }
  setResetServiceUnavialbility(error) {
    if (this.props.setResetServiceUnavialbilityWithDispatch) {
      this.props.setResetServiceUnavialbilityWithDispatch(error);
    }
  }
  /* If data props changes update the screens according to the data */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activePickTour !== nextProps.activePickTour) {
      this.setState({
        selectedProducts:
          nextProps.activePickTour && size(nextProps.activePickTour) > 0
            ? nextProps.activePickTour
            : [],
        skipItemValidation: nextProps.skippedPickTours && size(nextProps.skippedPickTours) > 0
      });
    }
    if (size(nextProps.skippedPickTours) > 0) {
      this.setState({ skipItemValidation: true });
    }
  }
  /* Call parent function to trigger the /print - API based on the screen */
  async onPrintClick() {
    const { currentStep } = this.props;
    if (this.props.onPrintClick) {
      let currentActiveScreen = -1;
      if (currentStep === 2) {
        currentActiveScreen = 1;
      } else if (currentStep === 3) {
        currentActiveScreen = 2;
      }
      try {
        this.setState({
          loading: true
        });
        await this.props.onPrintClick(currentActiveScreen);
      } finally {
        this.setState({
          loading: false
        });
      }
    }
  }
  // Return headers by screen number
  getHeader() {
    const { currentStep } = this.props;
    if (currentStep === 2) {
      return HEADERS.ACTIVE_PICKTOUR;
    } else if (currentStep === 0) {
      return "";
    } else if (currentStep === 3) {
      return HEADERS.SKIP_ITEM_VALIDATION;
    } else if (currentStep === 4) {
      return HEADERS.COMPLETED_PICKTOUR;
    } else {
      return HEADERS.PENDING_ORDERS;
    }
  }
  /* Get Action button to the top based on the screen number and header text */
  getActionButtonsSection(viewType) {
    const { selectedPickTour, selectedProducts } = this.state;
    const actionWrapperClass = viewType === VIEWS.DESKTOP ? "actionWrapper w-49" : "actionWrapper";
    const header = this.getHeader();
    return (
      <span className={actionWrapperClass}>
        {header !== "" && header === HEADERS.PENDING_ORDERS && (
          <Button
            id="startPicking"
            data-testid="ordering-start-picking"
            type="primary"
            size="sm"
            text="Start Picking"
            loading={this.state.loading}
            onButtonClick={this.onActiveStartPick.bind(this)}
            disabled={
              selectedPickTour && selectedPickTour.pickTourUID ? false : !size(selectedProducts) > 0
            }
          />
        )}
        {header !== "" &&
          (header === HEADERS.ACTIVE_PICKTOUR || header === HEADERS.SKIP_ITEM_VALIDATION) && (
            <Button
              id="printBtn"
              data-testid="ordering-print"
              type="primary"
              size="sm"
              onButtonClick={this.onPrintClick.bind(this)}
              text="Print"
              className="printBtn"
              loading={this.state.loading}
            />
          )}
      </span>
    );
  }

  getBodyTitle(viewType) {
    const header = this.getHeader();
    const { data, isAPIInProgress, isLoadingRefresh, storeId } = this.props;
    const selectParts = viewType === VIEWS.DESKTOP ? "noSelectParts" : "selectedParts";
    const INVOICES = "Select to start picking the parts.";
    const NO_INVOICES = "There are no invoices in the queue.";
    const NO_STORE_SET = "There's no store set for picking. Please select one.";
    const refreshOrders = () => {
      eventLogger.infoWithMetadata("User clicked refresh orders", JSON.stringify({}));
      return this.props.getOrders(true, true);
    };
    if (header === HEADERS.PENDING_ORDERS) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <label className={selectParts}>
            {data && (size(data.orders) > 0 || size(data.pickTours) > 0)
              ? INVOICES
              : !storeId
              ? NO_STORE_SET
              : NO_INVOICES}
          </label>
          <div
            className="refreshBlock"
            data-testid="ordering-link-refresh-orders"
            onClick={isAPIInProgress ? undefined : refreshOrders}
          >
            <img
              className={
                isAPIInProgress && isLoadingRefresh
                  ? "refreshIconOrdersRotating"
                  : "refreshIconOrders"
              }
              alt="refresh"
              src={refresh}
              width="20"
              height="20"
            />
            <Link
              className={isAPIInProgress && isLoadingRefresh ? "refreshDisabled" : "refreshEnabled"}
            >
              Refresh list
            </Link>
          </div>
        </div>
      );
    }
  }
  getHeaderSize() {
    const header = this.getHeader();
    if (header === HEADERS.PENDING_ORDERS) {
      return "wid-60";
    } else if (header === HEADERS.ACTIVE_PICKTOUR) {
      return "wid-60";
    } else if (header === HEADERS.SKIP_ITEM_VALIDATION) {
      return "wid-100";
    } else if (header === HEADERS.COMPLETED_PICKTOUR) {
      return "wid-60";
    } else {
      return "";
    }
  }
  getActivePickTour(activePickTour) {
    return activePickTour;
  }
  getBody(viewType) {
    const header = this.getHeader();
    const {
      data,
      skippedPickTours,
      activePickTour,
      removeSkippedPickTour,
      updateSkipPickTour,
      setResetServiceUnavialbility,
      skippedFlag,
      user,
      completedPickTourList,
      isSkipped,
      createPickTour,
      totalCount,
      skipCompletedList,
      currentStep,
      isAPIInProgress
    } = this.props;
    const {
      skipItemValidation,
      completePick,
      confirmClicked,
      selectedPickTour,
      selectedProducts,
      activePickTourUID
    } = this.state;
    const wrapperClass = viewType === VIEWS.DESKTOP ? "invoicesItemsWrapper" : "";
    if (header === HEADERS.PENDING_ORDERS) {
      return (
        <div className={wrapperClass}>
          {data && (
            <ListLayout
              orders={data.orders}
              pickTours={data.pickTours}
              currentStep={currentStep}
              inPickProcess={skipItemValidation || completePick}
              selectedProducts={selectedProducts}
              activePickTourUID={activePickTourUID}
              selectedPickTour={selectedPickTour}
              viewType={viewType}
              onSelectedProducts={this.onSelectedProducts.bind(this)}
              onSelectedPickTour={this.onSelectedPickTour.bind(this)}
              upDateIsSavePicktourFlag={this.upDateIsSavePicktourFlag.bind(this)}
              onRetryFinalization={this.onRetryFinalization.bind(this)}
            />
          )}
        </div>
      );
    } else if (header === "") {
      return <div />;
    } else if (header === HEADERS.ACTIVE_PICKTOUR) {
      return (
        <ActiveViewOrders
          isMobileView={false}
          currentStep={currentStep}
          data={data}
          createPickTour={createPickTour}
          completedPickTourList={completedPickTourList}
          activePickTour={this.getActivePickTour(activePickTour)}
          totalCount={totalCount}
          selectedProducts={selectedProducts}
          onUpdatePickTour={this.onUpdatePickTour.bind(this)}
          handleSkipValidation={this.handleSkipValidation.bind(this)}
          upDateIsSavePicktourFlag={this.upDateIsSavePicktourFlag.bind(this)}
          errors={this.props.errors}
          isAPIInProgress={isAPIInProgress}
        />
      );
    } else if (header === HEADERS.SKIP_ITEM_VALIDATION) {
      return (
        <div className="overall-border  mar-lt-rt4">
          <SkipItemValidation
            skippedPickTours={skippedPickTours}
            skipCompletedList={skipCompletedList}
            totalCount={totalCount}
            currentStep={currentStep}
            isMobileView={false}
            removeSkippedPickTour={removeSkippedPickTour}
            updateSkipPickTour={updateSkipPickTour}
            setResetServiceUnavialbility={setResetServiceUnavialbility}
            skippedFlag={skippedFlag}
            createPickTour={createPickTour}
            user={user}
            getPickTourListForSkipValidation={this.getPickTourListForSkipValidation.bind(this)}
            handleCompletedPage={this.handleCompletedPage.bind(this)}
            upDateIsSavePicktourFlag={this.upDateIsSavePicktourFlag.bind(this)}
            errors={this.props.errors}
            notifyConfirmSkipServiceError={this.props.notifyConfirmSkipServiceError}
            notifyItemFoundServiceError={this.props.notifyItemFoundServiceError}
            clearServiceErrorNotifications={this.props.clearServiceErrorNotifications}
            isAPIInProgress={isAPIInProgress}
          />
        </div>
      );
    } else {
      return (
        <CompletedPick
          errors={this.props.errors}
          currentStep={currentStep}
          confirmClicked={confirmClicked}
          isSkipped={isSkipped}
          handleCompleted={this.handleCompleted.bind(this)}
          upDateIsSavePicktourFlag={this.upDateIsSavePicktourFlag.bind(this)}
          isSat2Sat={
            this.props.createPickTour && this.props.createPickTour.pickTourList
              ? this.props.createPickTour.pickTourList.some(
                  (pickTour) => pickTour.pickListTypeCD === "SAT2SAT"
                )
              : false
          }
        />
      );
    }
  }
  upDateIsSavePicktourFlag(flag) {
    this.props.upDateIsSavePicktourFlag(flag);
  }
  onRetryFinalization = (pickTour) => {
    if (this.props.onRetryFinalization) {
      this.props.onRetryFinalization(pickTour);
    }
  };
  render() {
    const { isServiceUnavailable, errors, currentStep } = this.props;
    const {
      skipItemValidation,
      completePick,
      isItemScanningRequired,
      isCustomerNote,
      noteObject
    } = this.state;
    const header = this.getHeader();
    const mobileSectionId =
      header === HEADERS.COMPLETED_PICKTOUR ? "mainInvoiceSection" : "invoiceSection";
    return (
      <div id="ordering">
        {/* Modal Section */}
        <CustomerNoteToStore
          modalState={isCustomerNote}
          noteObject={noteObject}
          errors={errors}
          onCloseCustomerStore={() => this.setState({ isCustomerNote: false, noteObject: null })}
          loading={this.state.loading}
          onCustomerStoreSubmit={() =>
            this.setState({ isCustomerNote: false, noteObject: null }, () =>
              this.onStartPickLogic()
            )
          }
          apiInProgress={this.props.apiInProgress}
        />
        <ItemScanningRequired
          isItemScanningRequired={isItemScanningRequired}
          onResetItemScanningRequired={this.onResetItemScanningRequired.bind(this)}
        />
        {isServiceUnavailable && (
          <NoInterNet
            setResetServiceUnavialbility={this.setResetServiceUnavialbility.bind(this)}
            header={this.getHeader()}
            HEADER_CONST={HEADERS}
            getOrders={() => {
              this.props.getOrders();
            }}
          />
        )}
        {/* Desktop View */}
        {!isServiceUnavailable && (
          <Fragment>
            <div style={{ height: "auto" }}>
              <MediaQuery minWidth={481}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {currentStep !== 4 && errors && errors.isStartPickTourError && (
                      <ErrorMessage message="starting pick tour" />
                    )}
                    {currentStep !== 4 && errors && errors.isPrintError && (
                      <ErrorMessage message="printing" />
                    )}
                    <div className="controlSection w-100">
                      {/* Header Section */}
                      {header !== "" && <Header size={4} text={header} css="header w-75" />}
                      {/* Actions Section */}
                      {header !== "" && this.getActionButtonsSection(VIEWS.DESKTOP)}
                    </div>
                    <hr className="hrRule" />
                  </Col>
                </Row>
              </MediaQuery>
              {/* Mobile View */}
              <MediaQuery maxWidth={480}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {currentStep !== 4 && errors && errors.isStartPickTourError && (
                      <ErrorMessage message="starting pick tour" />
                    )}
                    {currentStep !== 4 && errors && errors.isPrintError && (
                      <ErrorMessage message="printing" />
                    )}
                    <div className="controlSection">
                      <div className={`headerWrapper ${this.getHeaderSize()}`}>
                        {/* Header Section */}
                        {header !== "" && <Header size={4} text={header} css="header" />}
                      </div>
                      {/* Actions Section */}
                      {header !== "" && this.getActionButtonsSection(VIEWS.MOBILE)}
                    </div>
                    <hr
                      className={
                        (!(this.props.activePickTour || []).length > 0 &&
                          !completePick &&
                          !skipItemValidation &&
                          "hrRule") ||
                        "hrRuleFornextSteps"
                      }
                    />
                  </Col>
                </Row>
              </MediaQuery>
            </div>
            <MediaQuery minWidth={481}>
              {currentStep !== 4 && errors && errors.isGetOrdersRefreshError && (
                <Row className="refreshRow">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <ErrorMessage message="refreshing" />
                  </Col>
                </Row>
              )}
              <div style={{ height: "auto" }}>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {this.getBodyTitle(VIEWS.DESKTOP)}
                  </Col>
                </Row>
              </div>
              <section id="invoiceSectionDesktop">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    {this.getBody(VIEWS.DESKTOP)}
                  </Col>
                </Row>
              </section>
            </MediaQuery>
            <MediaQuery maxWidth={480}>
              {currentStep !== 4 && errors && errors.isGetOrdersRefreshError && (
                <Row className="refreshRow">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <ErrorMessage message="refreshing" />
                  </Col>
                </Row>
              )}
              <div style={{ height: "auto" }}>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    {this.getBodyTitle(VIEWS.MOBILE)}
                  </Col>
                </Row>
              </div>
              <section id={mobileSectionId}>
                <Row>
                  <Col xs={8} sm={8} md={8} lg={8}>
                    {this.getBody(VIEWS.MOBILE)}
                  </Col>
                </Row>
              </section>
            </MediaQuery>
          </Fragment>
        )}
      </div>
    );
  }
}
