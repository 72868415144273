import React, { Component } from "react";
import { toArray, groupBy, size } from "lodash";
import Divider from "@material-ui/core/Divider";
import OButton from "../core-libs/components/Button";
import ADialog from "../core-libs/components/Dialog";
import ErrorMessage from "../core-libs/components/ErrorMessage";
const closeIcon = require("../assets/images/close-x.svg");
export default class TransferStore extends Component {
  state = {
    loading: false
  };
  constructData(recieveStore) {
    let data = [];
    let _groupedPartNumberList = toArray(
      groupBy(recieveStore.pickObj.pickTourList, "partNumberID")
    );
    _groupedPartNumberList.forEach((item, index) => {
      if (size(item) > 1) {
        let _sumQty = 0;
        item.forEach((childItem) => {
          _sumQty = _sumQty + (childItem.orderItemQY - childItem.pickedItemQY);
        });
        let _item = Object.assign(
          {},
          {
            partNumberID: item[0].partNumberID,
            qty: _sumQty
          }
        );
        data.push(_item);
      } else {
        let _item = Object.assign(
          {},
          {
            partNumberID: item[0].partNumberID,
            qty: item[0].orderItemQY - item[0].pickedItemQY
          }
        );
        data.push(_item);
      }
    });
    return data;
  }
  onFulfillManually = async () => {
    try {
      this.setState({
        loading: true
      });
      await this.props.onForWardManually();
    } finally {
      this.setState({
        loading: false
      });
    }
  };
  constructedProps() {
    const { modalState, skippedStoreDetails, errors } = this.props;

    let _data =
      skippedStoreDetails &&
      skippedStoreDetails.pickObj &&
      skippedStoreDetails.pickObj.pickTourList &&
      skippedStoreDetails.pickObj.pickTourList.length > 0
        ? this.constructData(skippedStoreDetails)
        : [];
    return {
      active: modalState,
      title: "",
      bodyText: (
        <div id="transferStoreDialog">
          <div className="transferTitle">
            <div className="transferText">Transfer Store</div>
            <div className="closeBtn">
              <span className="closeImg" onClick={() => this.props.onToggleTransferStore(false)}>
                <img alt="closeIcon" src={closeIcon} />
              </span>
            </div>
          </div>
          {skippedStoreDetails && (
            <div className="transferBody">
              <div id="bodyLine1">
                {/* <div id="bodyLabel">Skipped Item(s) Available at:</div>
                <div id="bodyValue">
                  Store {skippedStoreDetails.receivingStoreId.replace(/^0+/, "")}
                </div> */}
                <div id="bodyLabel">Fulfill order from sister store/HUB/VDP</div>
              </div>
              <div id="bodyLine2">
                <div id="bodyLabel">Part #</div>
                <div id="bodyValue">Qty.</div>
              </div>
              {_data.map((item, index) => {
                return (
                  <div key={`skippedListStore.${index}`} id="bodyLine3">
                    <div id="bodyLabel">{item.partNumberID}</div>
                    <div id="bodyValue">
                      <div className="din1">{item.qty}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {errors && errors.isForwardError && (
            <div id="transferItemError">
              <ErrorMessage message="submitting" />
            </div>
          )}
          {errors && errors.isCompleteItemError && (
            <div id="transferItemError">
              <ErrorMessage message="submitting" />
            </div>
          )}
          <Divider />
          <div className="transferActionsWrapper">
            {/* <OButton
              id="proceedBtn"
              text="Transfer Item"
              customClass="transferForward"
              onButtonClick={() => this.props.forwardOrder()}
            /> */}
            <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
              <OButton
                id="cancelBtn"
                text="FulFill Manually"
                customClass="transferFulfilManually"
                onButtonClick={this.onFulfillManually}
                data-testid="transfer-store-forward-manually"
                loading={this.state.loading}
              />
            </div>
          </div>
        </div>
      )
    };
  }
  render() {
    return <ADialog modalProps={this.constructedProps()} />;
  }
}
