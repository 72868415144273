import { inventoryReducer } from "./inventory";
import { userReducer } from "./user";
import { storeIdReducer } from "./store";
import { serviceUnavailableReducer } from "./serviceUnavailable";
import { errorsReducer } from "./errors";

export const reducers = {
  inventory: inventoryReducer,
  store: storeIdReducer,
  user: userReducer,
  serviceUnavailable: serviceUnavailableReducer,
  errors: errorsReducer
};
