import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { reducers } from "../reducers/";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* root persistor */
const persistConfig = {
  key: "root",
  storage
};

const middleware = [thunk];
const rootReducer = combineReducers(reducers);
const reducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));

export const persistor = persistStore(store);
