import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import { truncate } from "lodash";
import OButton from "../core-libs/components/Button";
import ADialog from "../core-libs/components/Dialog";
const closeIcon = require("../assets/images/close-x.svg");
export default class CustomerNoteToStore extends Component {
  constructedProps() {
    const { modalState, noteObject } = this.props;
    return {
      active: modalState,
      title: "",
      bodyText: (
        <div id="customerStoreDialog">
          <div className="customerTitle">
            <div className="customerText">CUSTOMER NOTE TO STORE</div>
            <div className="closeBtn">
              <span
                className="closeImg"
                onClick={() => this.props.onCloseCustomerStore()}
                data-testid="customer-note-to-store-close"
              >
                <img alt="closeIcon" src={closeIcon} />
              </span>
            </div>
          </div>
          <div className="scrollWrapper">
            {noteObject &&
              noteObject.map((item, index) => {
                return (
                  <div key={`customerNote.${index}`}>
                    <div className="pinNameSection">
                      <div className="nameWrapper">
                        <div className="nameKey">Name:</div>
                        <div className="nameValue">
                          {truncate(item.customerOrganizationLNM, {
                            length: 10,
                            omission: "..."
                          })}
                        </div>
                      </div>
                      <div className="pinWrapper">
                        <div className="pinKey">PIN:</div>
                        <div className="pinValue">{item.deliverToCustomerCommercialID}</div>
                      </div>
                    </div>
                    <div className="customerBody">
                      <div id="bodyLine1">
                        <div id="bodyLabel">{item.customerShopNoteLTX}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <Divider />
          <div className="customerActionsWrapper">
            <OButton
              id="confirmBtn"
              text="Confirm"
              customClass="btnOk"
              onButtonClick={this.props.onCustomerStoreSubmit}
              data-testid="customer-note-to-store-submit"
              loading={this.props.loading}
            />
            <OButton
              id="cancelBtn"
              text="Cancel"
              customClass="btnCancel"
              onButtonClick={() => this.props.onCloseCustomerStore()}
              data-testid="customer-note-to-store-cancel"
            />
          </div>
        </div>
      )
    };
  }
  render() {
    return <ADialog modalProps={this.constructedProps()} />;
  }
}
