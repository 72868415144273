/* polyfill to support IE edge/firefox */
import "@babel/polyfill";
import "url-polyfill";
import * as React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import ThemeProvider from "react-toolbox/lib/ThemeProvider";
import { ErrorBoundary } from "react-error-boundary";
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from "./store";
import AppContainer from "./containers/AppContainer";
import { AuthProvider } from "./components/AuthContext";
import * as serviceWorker from "./serviceWorker";
/* react-toolbox create-react-app */
import theme from "./assets/react-toolbox/theme";
import eventLogger from "./utils/eventLogger";

import ErrorFallback from "./containers/ErrorFallback";

require("./index.scss");

function App() {
  React.useEffect(() => {
    eventLogger.infoWithMetadata("App is initialized", JSON.stringify({}));
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <AppContainer />
          </AuthProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

render(
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <App />
  </ErrorBoundary>,
  // eslint-disable-next-line no-restricted-properties
  document.getElementById("app")
);

serviceWorker.unregister();
