import { utcFormat } from "../utils/azday";
import * as types from "../constants/actionTypes";
import { GET_STORES, GET_EMPLOYEE_DATA_PREFIX, GET_EMPLOYEE_DATA_SUFFIX } from "../constants/URL";
import fetcher from "../utils/fetcher";

const ENV = window.env;

async function getTokenInfo() {
  const { data } = await fetcher.get(`${ENV.GATEWAY_BASE_URL}tokeninfo`, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
  });

  return {
    ...data,
    ordersDownloadedTime: utcFormat() // TODO: move somewhere else
  };
}

async function getStores(payload) {
  const { data } = await fetcher.post(GET_STORES, payload);
  return data;
}

async function getMetadata(uid) {
  const { data } = await fetcher.get(
    `${GET_EMPLOYEE_DATA_PREFIX}/${uid}${GET_EMPLOYEE_DATA_SUFFIX}`,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    }
  );
  return data;
}

function updateStoreFromMetadata(uid) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.IS_FETCHING_EMPLOYEE_DATA, payload: true });
      const metadata = await getMetadata(uid);
      if (metadata.locationCode === "STORES" && metadata.storeID && !getState().store.storeId) {
        dispatch({ type: types.MANUAL_STORE_UPDATE, payload: true, storeID: metadata.storeID });
      } else if (!getState().store.storeId) {
        dispatch({ type: types.MANUAL_STORE_UPDATE, payload: true });
      }
    } catch (error) {
      if (!getState().store.storeId) {
        dispatch({ type: types.MANUAL_STORE_UPDATE, payload: true });
      }
    } finally {
      dispatch({ type: types.IS_FETCHING_EMPLOYEE_DATA, payload: false });
    }
  };
}

export function updateUserDetails() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.IS_FETCHING_TOKENINFO, payload: true });
      const userData = getState().user.userDetails || (await getTokenInfo());
      dispatch({ type: types.IS_FETCHING_TOKENINFO, payload: false });
      dispatch({ type: types.SET_USER_DETAILS, payload: userData });
      if (!getState().store.storeId) {
        await updateStoreFromMetadata(userData.claims.uid)(dispatch, getState);
      }
    } finally {
      dispatch({ type: types.IS_FETCHING_TOKENINFO, payload: false });
    }
  };
}

export function updateStore(body) {
  return async (dispatch, getState) => {
    if (!body || getState().store.storeId) {
      return;
    }
    try {
      const data = await getStores(body);
      if (!getState().store.storeId) {
        dispatch({
          type: types.REQUEST_STORE_ID_SUCCESS,
          payload: data.stores[0].storeId.toString()
        });
      }
    } catch (error) {
      if (!getState().store.storeId) {
        dispatch({ type: types.MANUAL_STORE_UPDATE, payload: true });
      }
    }
  };
}

export const updateManualStore = (value) => {
  return (dispatch) => {
    return dispatch({ type: types.MANUAL_STORE_UPDATE, payload: value });
  };
};

export const updateManualStoreWithSuccess = (value, manualStoreNumber) => {
  return (dispatch) => {
    dispatch({ type: types.MANUAL_STORE_UPDATE, payload: value });
    return dispatch({ type: types.REQUEST_STORE_ID_SUCCESS, payload: manualStoreNumber });
  };
};
