import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import { toArray, groupBy, size } from "lodash";
import OButton from "../core-libs/components/Button";
import ADialog from "../core-libs/components/Dialog";
import ErrorMessage from "../core-libs/components/ErrorMessage";
export default class NoStore extends Component {
  state = {
    loading: false
  };

  constructData(noRecevingStore) {
    let data = [];
    let _groupedPartNumberList = toArray(groupBy(noRecevingStore.pickTourList, "partNumberID"));
    _groupedPartNumberList.forEach((item) => {
      if (size(item) > 1) {
        let _sumQty = 0;
        item.forEach((childItem) => {
          _sumQty = _sumQty + (childItem.orderItemQY - childItem.pickedItemQY);
        });
        let _item = Object.assign(
          {},
          {
            partNumberID: item[0].partNumberID,
            qty: _sumQty
          }
        );
        data.push(_item);
      } else {
        let _item = Object.assign(
          {},
          {
            partNumberID: item[0].partNumberID,
            qty: item[0].orderItemQY - item[0].pickedItemQY
          }
        );
        data.push(_item);
      }
    });
    return data;
  }

  onConfirmNoStore = async () => {
    try {
      this.setState({
        loading: true
      });
      await this.props.confirmnoStore();
    } finally {
      this.setState({ loading: false });
    }
  };

  constructedProps() {
    const { modalState, noRecevingStore, errors } = this.props;
    let _data =
      noRecevingStore && noRecevingStore.pickTourList && noRecevingStore.pickTourList.length > 0
        ? this.constructData(noRecevingStore)
        : [];
    return {
      active: modalState,
      title: "",
      bodyText: (
        <div id="noStoreDialog">
          <div className="noTitle">
            <div className="noText">VDP or Outside buy item</div>
          </div>
          <div className="noBody">
            <div id="bodyLine1">
              <p>
                Skipped Items are not available in sister store network. Source these items through
                VDP or Outside Buy.
              </p>
            </div>
            <div id="bodyLine2">
              <div id="bodyLabel">Part #</div>
              <div id="bodyValue">Qty.</div>
            </div>

            {_data.map((item, index) => {
              return (
                <div key={`noStore.${index}`} id="bodyLine3">
                  <div id="bodyLabel">{item.partNumberID}</div>
                  <div id="bodyValue">{item.qty}</div>
                </div>
              );
            })}
          </div>
          {errors && errors.isCompleteItemError && (
            <div id="noStoreError">
              <ErrorMessage message="submitting" />
            </div>
          )}
          <Divider />
          <div className="noActionsWrapper">
            <OButton
              id="proceedBtn"
              text="Confirm"
              customClass="noForward"
              onButtonClick={this.onConfirmNoStore}
              data-testid="no-store-confirm"
              loading={this.state.loading}
            />
          </div>
        </div>
      )
    };
  }
  render() {
    return <ADialog modalProps={this.constructedProps()} />;
  }
}
