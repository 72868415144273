import React from "react";

const LoadingIndicator = () => {
  return (
    <div className="loading-indicator-container">
      <span />
      <span />
      <span />
      <span />
    </div>
  );
};

export default LoadingIndicator;
