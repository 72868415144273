import * as types from "../constants/actionTypes";

const initialState = { storeId: "", isLoading: false };

export const storeIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_STORE_ID_SUCCESS:
      return { ...state, storeId: action.payload };
    case types.IS_FETCHING_EMPLOYEE_DATA:
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};
