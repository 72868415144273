import React from "react";
const alertIcon = require("../../assets/images/Error.svg");

const ErrorMessage = (props) => {
  return (
    <div id="errorMessageWrapper">
      <div className="alertIcon">
        <img alt="alertIcon" src={alertIcon} />
      </div>
      <div id="errorMessage">{`Error ${props.message} - Please try again.`}</div>
    </div>
  );
};

export default ErrorMessage;
