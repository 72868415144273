import * as types from "../constants/actionTypes";

const initialState = {
  isManualStore: false,
  isFetchingTokenInfo: false,
  userDetails: undefined,
  employeeStore: undefined
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_DETAILS: {
      return { ...state, userDetails: action.payload };
    }
    case types.REMOVE_STORE_DATA: {
      return initialState;
    }
    case types.MANUAL_STORE_UPDATE: {
      return {
        ...state,
        isManualStore: action.payload,
        employeeStore: action.storeID
      };
    }
    case types.IS_FETCHING_TOKENINFO:
      return { ...state, isFetchingTokenInfo: action.payload };
    default:
      return state;
  }
};
