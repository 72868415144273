import React, { Component, Fragment } from "react";
import { trim, size, orderBy, truncate, includes, some, first, last, find } from "lodash";
/* Core Library */
import Divider from "@material-ui/core/Divider";
import MediaQuery from "react-responsive";
import Tooltip from "react-simple-tooltip";
import OButton from "../core-libs/components/Button";
import ADialog from "../core-libs/components/Dialog";
import ErrorMessage from "../core-libs/components/ErrorMessage";
import eventLogger from "../utils/eventLogger";
import BarcodeReader from "./BarcodeReader";
import TextInputScanner from "./TextInputScanner";

const closeIcon = require("../assets/images/close-x.svg");
const alertIcon = require("../assets/images/Error.svg");

const initialState = {
  partQty: "",
  orderPickedQty: "",
  showValidationModal: false,
  validationBarcodeValue: "",
  isScannedUPCInvalid: false,
  barCodeList: [],
  byDefaultBGColor: "bgGrey",
  isQtyInputFocused: false,
  loading: false
};
const initialStateWithoutOrderPickedQty = {
  partQty: "",
  showValidationModal: false,
  validationBarcodeValue: "",
  isScannedUPCInvalid: false,
  barCodeList: [],
  byDefaultBGColor: "bgGrey",
  isQtyInputFocused: false,
  loading: false
};

const deviceInfo = navigator.userAgent;
const isTC75 = deviceInfo.includes("TC75");

export default class ActiveViewOrders extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.handleScan = this.handleScan.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activePickTour !== prevProps.activePickTour &&
      this.props.totalCount !== prevProps.totalCount
    ) {
      this.setState({ partQty: "" });
    }
  }

  componentDidMount() {
    // We're in "Active Pick Tour" here, which is supposed to be a separate
    // route, but it isn't just yet. For now, we prevent the default back button
    // behavior, by pushing to browser history. The user is prompted with a
    // confirmation modal and goes back to the "Pending Orders" screen or not.
    // Otherwise they'd end up outside of the app root, in login gateway or
    // blank tab.
    window.addEventListener("popstate", this.preventDefaultBrowserBackButton);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.preventDefaultBrowserBackButton);
  }

  preventDefaultBrowserBackButton = () => {
    window.history.pushState(null, document.title, window.location.href);

    const { currentStep, upDateIsSavePicktourFlag } = this.props;
    if (currentStep === 2) {
      upDateIsSavePicktourFlag(true);
    } else {
      upDateIsSavePicktourFlag(false);
    }
  };

  onAutoComplete(evt, item, isLastRecord) {
    evt.preventDefault();
    const { orderPickedQty } = this.state;
    if (evt.keyCode === 13 || (evt.keyCode === 9 && isTC75)) {
      if (orderPickedQty !== "" && orderPickedQty >= 0 && orderPickedQty <= item.orderItemQY) {
        let _qty = parseInt(trim(evt.target.value));
        let _qtyLimit = parseInt(item.orderItemQY);
        if ((_qty || _qty === 0) && !this.props.isAPIInProgress) {
          this.onUpdatePickTour(item, _qty, isLastRecord, false, true);
          this.setState({
            orderPickedQty: _qty,
            partQty: _qtyLimit - _qty,
            byDefaultBGColor: ""
          });
        } else {
          this.setState({
            orderPickedQty: "",
            partQty: "",
            byDefaultBGColor: ""
          });
        }
      }
    }
  }
  // update the required quantity
  onValueChange(evt, qtyLimit) {
    evt.preventDefault();
    let _qty = parseInt(trim(evt.target.value));
    let _qtyLimit = parseInt(qtyLimit);
    if (_qty <= _qtyLimit && _qty >= 0) {
      this.setState({
        orderPickedQty: _qty,
        partQty: _qtyLimit - _qty,
        byDefaultBGColor: ""
      });
    } else {
      this.setState({
        orderPickedQty: "",
        partQty: "",
        byDefaultBGColor: ""
      });
    }
  }

  /* Based on the data create the DOM for active pick tour */
  async onSuccessFlow(pickTour, orderPickedQty, isLastRecord, isFromSkip, isSat2Sat) {
    if (this.props.onUpdatePickTour && !this.props.isAPIInProgress) {
      eventLogger.infoWithMetadata(
        `Calling ActiveViewOrders onSuccessFlow`,
        JSON.stringify({
          timestamp: new Date().toUTCString(),
          pickTour: pickTour,
          isLastRecord: isLastRecord,
          isAPIInProgress: this.props.isAPIInProgress
        })
      );

      try {
        this.setState({ loading: true });
        await this.props.onUpdatePickTour(
          pickTour,
          orderPickedQty,
          isLastRecord,
          isFromSkip,
          isSat2Sat
        );
      } finally {
        this.setState({ loading: false });
      }
    }
    this.props.handleSkipValidation();
    this.setState({ ...initialStateWithoutOrderPickedQty });
  }
  // trigger update api action
  onUpdatePickTour(
    pickTour,
    orderPickedQty,
    isLastRecord,
    isFromSkip = false,
    isManualEntry = false
  ) {
    const { barCodeList } = this.state;
    const { createPickTour } = this.props;
    const isSat2Sat = createPickTour.pickTourList.some(
      (pickTourItem) => pickTourItem.pickListTypeCD === "SAT2SAT"
    );
    // if quantity more then 0 or equal to 0
    if (orderPickedQty > 0 && !this.props.isAPIInProgress) {
      // if selected order requires the barcode scanning and scanned upc array more than 0
      if (
        (barCodeList && size(barCodeList) > 0) ||
        (createPickTour && createPickTour.isUPCValidationScanRequiredFL === "1")
      ) {
        // if the scanned barcode is valid call /update api
        let _Qty = orderPickedQty;
        if (_Qty <= pickTour.orderItemQY) {
          if (
            createPickTour.isUPCValidationScanRequiredFL === "1" &&
            size(barCodeList) === 0 &&
            isManualEntry
          ) {
            this.setState({ showValidationModal: true });
          }
          //ASHWIN - Only difference here is this middle condition. Everything else looks fine
          else if (
            createPickTour.isUPCValidationScanRequiredFL === "1" &&
            size(barCodeList) === 0 &&
            !isManualEntry
          ) {
            eventLogger.infoWithMetadata(
              `Calling ActiveViewOrders onUpdatePickTour w/ onSuccessFlow (inner Else if Statement)`,
              JSON.stringify({
                timestamp: new Date().toUTCString(),
                pickTour: pickTour
              })
            );

            //ASHWIN - This middle case is adding this setState false.
            this.setState({ showValidationModal: false });
            this.onSuccessFlow(pickTour, _Qty, isLastRecord, isFromSkip, isSat2Sat);
          } else {
            eventLogger.infoWithMetadata(
              `Calling ActiveViewOrders onUpdatePickTour w/ onSuccessFlow (inner else Statement)`,
              JSON.stringify({
                timestamp: new Date().toUTCString(),
                pickTour: pickTour
              })
            );
            this.onSuccessFlow(pickTour, _Qty, isLastRecord, isFromSkip, isSat2Sat);
          }
        }
      } else {
        // call the /update api
        eventLogger.infoWithMetadata(
          `Calling ActiveViewOrders onUpdatePickTour w/ onSuccessFlow (middle else Statement)`,
          JSON.stringify({
            timestamp: new Date().toUTCString(),
            pickTour: pickTour
          })
        );
        this.onSuccessFlow(pickTour, orderPickedQty, isLastRecord, isFromSkip, isSat2Sat);
      }
    } else {
      // call the /update api
      eventLogger.infoWithMetadata(
        `Calling ActiveViewOrders onUpdatePickTour w/ onSuccessFlow (outer else Statement)`,
        JSON.stringify({
          timestamp: new Date().toUTCString(),
          pickTour: pickTour
        })
      );
      this.onSuccessFlow(pickTour, orderPickedQty, isLastRecord, isFromSkip, isSat2Sat);
    }
  }
  /* construct DOM for POG */
  getPOGSEQByPartNumber(createPickTour, partNumberID, pId) {
    const _pickTourList = createPickTour && createPickTour.pickTourList;
    const isSat2Sat = createPickTour.pickTourList.some(
      (pickTourItem) => pickTourItem.pickListTypeCD === "SAT2SAT"
    );
    const _selectePickTour =
      _pickTourList &&
      size(_pickTourList) > 0 &&
      _pickTourList.find((item) =>
        isSat2Sat ? item.replenishmentOrderHeaderUID === pId : item.partNumberID === partNumberID
      );
    const _pogSeqDom =
      _selectePickTour && _selectePickTour.location && size(_selectePickTour.location) > 0 ? (
        orderBy(_selectePickTour.location, ["vdlSequenceNumber"], ["asc"]).map((item) => {
          return (
            <div className="planogramWrapper" key={item.planoID}>
              <div className="planoGramLabel">
                {item.planoDescription !== "unavailable" && (
                  <Tooltip
                    padding={5}
                    placement="top"
                    content={item.planoDescription}
                    customCss={`& > div {width: 100% !important; & { div:nth-child(1) { word-break: break-all; font-size: 12px; } } }`}
                  >
                    <span className="pogLabel">
                      {truncate(item.planoDescription, {
                        length: 25,
                        omission: "..."
                      })}
                    </span>
                  </Tooltip>
                )}
                {item.planoDescription === "unavailable" && (
                  <Tooltip
                    padding={5}
                    placement="top"
                    content={item.planoID}
                    customCss={`& > div {width: 100% !important; & { div:nth-child(1) { word-break: break-all; font-size: 12px; } } }`}
                  >
                    <span className="pogLabel">
                      {truncate(item.planoID, {
                        length: 20,
                        omission: "..."
                      })}
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="seqLabel">
                <div className="din">{item.itemSequenceNumber}</div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="planogramWrapper">
          <div className="planoGramLabel">
            <span className="pcgLabel">
              {isSat2Sat ? "Location Data Not Available" : "Hub/VDP/Sister Store"}
            </span>
          </div>
        </div>
      );
    return _pogSeqDom;
  }
  /* event for manual barcode modal entry submission */
  onSubmitValidationBarcode(item, _lastPick) {
    const { validationBarcodeValue, orderPickedQty } = this.state;
    const _barcodeDom = validationBarcodeValue;
    let that = this;
    const { createPickTour } = this.props;
    const isSat2Sat = createPickTour.pickTourList.some(
      (pickTourItem) => pickTourItem.pickListTypeCD === "SAT2SAT"
    );
    // if the typed text some barcode
    if (_barcodeDom !== "") {
      const _selectedPickTour = isSat2Sat
        ? createPickTour.pickTourList.find((pick) => pick.replenishmentOrderHeaderUID === item.pId)
        : find(createPickTour.pickTourList, ["partNumberID", item.partNumberID]);
      const upcArray = _selectedPickTour.upc.slice(",");
      const isInvalidUpc = upcArray && upcArray !== "" ? !includes(upcArray, _barcodeDom) : true;
      // if it matches upc array or part number then consider as valid and trigger /update api
      if (
        !isInvalidUpc ||
        this.stripSpecialChars(item.partNumberID.toLowerCase()) ===
          this.stripSpecialChars(_barcodeDom.toLowerCase())
      ) {
        let _Qty = orderPickedQty;
        if (_Qty <= item.orderItemQY) {
          this.setState(
            {
              validationBarcodeValue: "",
              showValidationModal: false
            },
            () => {
              this.onSuccessFlow(
                item,
                _Qty,
                isSat2Sat
                  ? _lastPick.pId === item.pId
                  : _lastPick.partNumberID === item.partNumberID,
                false,
                isSat2Sat
              );
            }
          );
        }
      } else {
        // show validation error message
        this.setState({
          isScannedUPCInvalid: isInvalidUpc,
          // showValidationModal: false,
          validationBarcodeValue: ""
        });
      }
    }
  }

  stripSpecialChars(str) {
    return str
      .replace(/\s/g, "")
      .replace(/#/g, "")
      .replace(/\s/, "")
      .replace(/-/g, "")
      .replace(/\//g, "")
      .replace(/\$/g, "")
      .replace(/&/g, "")
      .replace(/_/, "")
      .replace(/-/g, "")
      .replace(/!/, "")
      .replace(/\+/g, "")
      .replace(/=/g, "")
      .replace(/@/g, "")
      .replace(/%/g, "")
      .replace(/\*/g, "");
  }

  enterPressed(item, _lastPick, e) {
    let value = e.keyCode || e.which;
    if (value === 13) {
      this.onSubmitValidationBarcode(item, _lastPick);
    }
  }

  /* manual barcode entry modal */
  constructedProps(item, _lastPick) {
    const {
      showValidationModal,
      validationBarcodeValue,
      orderPickedQty,
      isScannedUPCInvalid
    } = this.state;
    const errors = this.props.errors;
    return {
      active: showValidationModal,
      title: "",
      bodyText: (
        <Fragment>
          <div id="validationModal">
            <div className="validationTitle">
              <div className="validationText">Error Scanning Item</div>
              <div className="closeBtn">
                <span
                  className="closeImg"
                  onClick={() => this.setState({ showValidationModal: false })}
                  data-testid="active-view-close"
                >
                  <img alt="closeIcon" src={closeIcon} />
                </span>
              </div>
            </div>

            <div className="validationBar">
              <div className="validationText">
                <span className="validationLabel">
                  Please enter Part #/Barcode and quantity manually.
                </span>
              </div>
            </div>

            <div className="validationBody">
              <div className="controls">
                <div className="input-hlder">
                  <input
                    id="validationBarcodeValue"
                    autoFocus={true}
                    size="sm"
                    type="text"
                    autoComplete="off"
                    placeholder=" "
                    value={validationBarcodeValue}
                    onChange={(e) => {
                      this.setState({
                        validationBarcodeValue: e.target.value,
                        isScannedUPCInvalid: false
                      });
                    }}
                    onKeyPress={this.enterPressed.bind(this, item, _lastPick)}
                  />
                  <span>Part #/Barcode</span>
                </div>
                <div className="validationQtySection">
                  <div className="input-hlder">
                    <input
                      id="validationQtyTextBox"
                      type="number"
                      min="0"
                      step="1"
                      pattern="[0-9]"
                      inputMode="numeric"
                      autoComplete="off"
                      className={
                        orderPickedQty.toString().length <= 1
                          ? `scanOrderQty `
                          : `skipItemOrderQtyBig `
                      }
                      value={orderPickedQty}
                      onChange={(event) => {
                        this.onValueChange(event, item.orderItemQY);
                      }}
                      onFocus={(e) => {
                        this.setState({
                          orderPickedQty: "",
                          byDefaultBGColor: ""
                        });
                      }}
                      onKeyDown={(e) => {
                        if (
                          !(
                            (e.keyCode > 95 && e.keyCode < 106) ||
                            (e.keyCode > 47 && e.keyCode < 58) ||
                            e.keyCode === 8 ||
                            e.keyCode === 229
                          )
                        ) {
                          e.preventDefault();
                          this.setState({ orderPickedQty: "" });
                        }
                      }}
                      placeholder=" "
                    />
                    <span className="qty">Qty</span>
                  </div>
                </div>
              </div>
              <div className="submittingError">
                {errors && errors.isPartSubmitError && <ErrorMessage message="submitting" />}
              </div>
            </div>
            {isScannedUPCInvalid && (
              <div id="validationScanCodeMessage">
                <div className="validationIcon">
                  <img alt="alertIcon" src={alertIcon} />
                </div>
                <div className="validationMessage">Item scanned does not match</div>
              </div>
            )}
            <Divider />
            <div className="validationAction">
              <OButton
                id="validationSubmitBtn"
                text="Submit"
                customClass="validationButtonItemScanModal"
                onButtonClick={() => {
                  this.onSubmitValidationBarcode(item, _lastPick);
                }}
                data-testid="active-view-validation-submit"
              />
              <OButton
                id="cancelBtn"
                text="Cancel"
                customClass="cancelButtonItemScanModal"
                onButtonClick={() =>
                  this.setState({
                    showValidationModal: false,
                    validationBarcodeValue: "",
                    isScannedUPCInvalid: false
                  })
                }
                data-testid="active-view-cancel"
              />
            </div>
          </div>
        </Fragment>
      )
    };
  }
  /* validation logic to check the scanned barcode value is exist in upc comma separated string which returned by /create api */
  isNotExistInValidUPC(firstPick, barCodeList) {
    const { createPickTour } = this.props;
    const isSat2Sat = createPickTour.pickTourList.some((pick) => pick.pickListTypeCD === "SAT2SAT");
    if (createPickTour && createPickTour.pickTourList && createPickTour.pickTourList.length > 0) {
      const _selectedPickTour = isSat2Sat
        ? createPickTour.pickTourList.find(
            (pick) => pick.replenishmentOrderHeaderUID === firstPick.pId
          )
        : find(createPickTour.pickTourList, ["partNumberID", firstPick.partNumberID]);

      const upcArray = _selectedPickTour.upc;
      const _newUpcArray =
        upcArray &&
        upcArray.length > 0 &&
        upcArray.split(",").map((item) => parseInt(item).toString());
      if (size(barCodeList) > 0) {
        return !some(barCodeList, (barCode) => {
          return some(_newUpcArray, (upc) => upc === barCode);
        });
      } else {
        return true;
      }
    }
  }

  //ASHWIN - I dont think the issue is in the handleScan function
  //ASHWIN - If the onUpdatePickTour call from handleScan is not the issue, then it is most likely coming from the new
  //ASHWIN - (cont.) ... variable isAPIInProgress added in the second MR.
  /* triggered whenever the barcode scanned */
  handleScan(data) {
    const { barCodeList } = this.state;
    const { createPickTour, activePickTour, isAPIInProgress } = this.props;
    const _firstPick = first(activePickTour);
    const _lastPick = last(activePickTour);
    const isSat2Sat = createPickTour.pickTourList.some((pick) => pick.pickListTypeCD === "SAT2SAT");
    let that = this;
    // convert string to integer so it can remove leading 0 and again make as string to match with validation logic and call api
    const _data = parseInt(data).toString();
    const isValid = !this.isNotExistInValidUPC(_firstPick, [_data]);
    if (isValid && !isAPIInProgress) {
      let _barCodeList = Object.assign([], barCodeList);

      //ASHWIN - This new === case was added.
      // ASHWIN - DIFFERENCE. === is calling onUpdatePickTour,
      // Ashwin - > is not calling onUpdatePickTour, as was originally called
      if (_firstPick.orderItemQY === size(_barCodeList) + 1) {
        eventLogger.infoWithMetadata(
          `Calling ActiveViewOrders handleScan First if condition`,
          JSON.stringify({
            timestamp: new Date().toUTCString(),
            data: data
          })
        );
        //Ashwin - add log here
        const item =
          activePickTour &&
          activePickTour.length > 0 &&
          activePickTour.find((o) =>
            isSat2Sat ? o.pId === _firstPick.pId : o.partNumberID === _firstPick.partNumberID
          );
        //ASHWIN - This is likely causing issue below
        this.onUpdatePickTour(
          item,
          size(_barCodeList) + 1,
          isSat2Sat ? _lastPick.pId === item.pId : _lastPick.partNumberID === item.partNumberID
        );
      } else if (_firstPick.orderItemQY > size(_barCodeList) + 1) {
        // if valid barcode push to barcode state array
        _barCodeList.push(_data);
        //Ashwin - ADDING NEW ITEM TO BARCODE LIST. Else case does not
        this.setState({
          isScannedUPCInvalid: false,
          barCodeList: _barCodeList,
          orderPickedQty: size(_barCodeList)
        });
        eventLogger.infoWithMetadata(
          `Calling ActiveViewOrders handleScan Second else if condition`,
          JSON.stringify({
            timestamp: new Date().toUTCString(),
            data: data
          })
        );
      } else {
        this.setState({
          isScannedUPCInvalid: false
        });
      }
    } else {
      this.setState({
        isScannedUPCInvalid: true,
        showValidationModal: true
      });
    }
  }
  // if any error occured while scanning show the manual barcode entry modal
  handleError(stringWriting, errorMsg) {
    this.setState({ showValidationModal: true });
    // throw new Error(err);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.activePickTour !== nextProps.activePickTour) {
      const _firstPick = first(nextProps.activePickTour);
      if (_firstPick) {
        this.setState({
          orderPickedQty: _firstPick.orderItemQY,
          byDefaultBGColor: "bgGrey"
        });
      }
    }
  }

  UNSAFE_componentWillMount() {
    const { activePickTour } = this.props;
    if (activePickTour) {
      const _firstPick = first(activePickTour);
      if (_firstPick) {
        this.setState({
          orderPickedQty: _firstPick.orderItemQY,
          byDefaultBGColor: "bgGrey"
        });
      }
    }
  }

  render() {
    const {
      activePickTour,
      totalCount,
      completedPickTourList,
      createPickTour,
      errors
    } = this.props;
    const { orderPickedQty, byDefaultBGColor, showValidationModal, isQtyInputFocused } = this.state;
    const isSat2Sat =
      createPickTour &&
      createPickTour.pickTourList.some((pick) => pick.pickListTypeCD === "SAT2SAT");
    const _totalCount = totalCount + size(completedPickTourList);
    const deviceInfo = navigator.userAgent;
    const isTC75 = deviceInfo.includes("TC75");
    return (
      <div id="activeListSection">
        {!isTC75 && (
          <BarcodeReader onError={this.handleError.bind(this)} onScan={this.handleScan} />
        )}
        {isTC75 && !showValidationModal && !isQtyInputFocused && (
          <TextInputScanner onScan={this.handleScan} />
        )}
        <div id="interactive" className="viewport displayNone" />
        {activePickTour &&
          activePickTour.map((item, index) => {
            const _firstPick = first(activePickTour);
            const _lastPick = last(activePickTour);
            return (
              <div
                key={"itemsActiveOrInactive." + index}
                className="activeOrdersWrapper overall-border"
              >
                {(isSat2Sat
                  ? _firstPick.pId === item.pId
                  : _firstPick.partNumberID === item.partNumberID) && (
                  <div key={"activeInactiveOrders." + index}>
                    <div className="selectedHeadingSection">
                      <div className="alignedItems">
                        <div className="labelPartIdWrapper">
                          <span className="labelPartId">Part #:</span>
                          <span className="labelPartIdValue">{item.partNumberID}</span>
                          <span className="noOfItems">
                            <span>{`${
                              index + size(completedPickTourList) + 1
                            } of ${_totalCount} Items`}</span>
                          </span>
                        </div>
                        <div className="quantityPickWrapper">
                          <div>
                            <span className="sku">SKU: </span>
                            <span className="skuValue">{item.itemID.replace(/^0+/, "")}</span>
                          </div>
                          <div>
                            <span className="labelQuantityPick">Qty. Requested:</span>
                            <span className="labelQuantityPickValue">{item.orderItemQY}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <MediaQuery minWidth={481}>
                      {errors && errors.isCompleteItemError && (
                        <div id="completeItemErrorWrapper">
                          <ErrorMessage message="completing item" />
                        </div>
                      )}
                    </MediaQuery>
                    <div className="actionAlignedItems">
                      <div className="selectedControlSection">
                        <div className="actionItems act500">
                          <div className="itemDesc">{item.itemDescription}</div>
                          <div className="itemQtyWrapper">
                            <span>
                              <input
                                id="qtyTextBox"
                                type="number"
                                min="0"
                                step="1"
                                pattern="[0-9]"
                                inputMode="numeric"
                                autoComplete="off"
                                className={
                                  orderPickedQty.toString().length <= 1
                                    ? `itemOrderQty ${byDefaultBGColor}`
                                    : `skipItemOrderQtyBig ${byDefaultBGColor}`
                                }
                                value={orderPickedQty}
                                onFocus={(e) => {
                                  this.setState({
                                    orderPickedQty: "",
                                    byDefaultBGColor: "",
                                    isQtyInputFocused: true
                                  });
                                }}
                                onBlur={(e) => {
                                  this.setState({
                                    isQtyInputFocused: false
                                  });
                                }}
                                onChange={(e) => {
                                  this.onValueChange(e, item.orderItemQY);
                                }}
                                onKeyUp={(event) =>
                                  this.onAutoComplete(
                                    event,
                                    item,
                                    isSat2Sat
                                      ? _lastPick.pId === item.pId
                                      : _lastPick.partNumberID === item.partNumberID
                                  )
                                }
                                onKeyDown={(e) => {
                                  if (
                                    e.keyCode !== 13 &&
                                    !(
                                      (e.keyCode > 95 && e.keyCode < 106) ||
                                      (e.keyCode > 47 && e.keyCode < 58) ||
                                      e.keyCode === 8 ||
                                      e.keyCode === 229 ||
                                      (e.keyCode === 9 && isTC75)
                                    )
                                  ) {
                                    e.preventDefault();
                                    this.setState({
                                      orderPickedQty: ""
                                    });
                                  }
                                }}
                              />
                            </span>
                          </div>
                          <MediaQuery minWidth={481}>
                            <div className="completeButtonWrapper">
                              <OButton
                                onButtonClick={this.onUpdatePickTour.bind(
                                  this,
                                  item,
                                  orderPickedQty,
                                  isSat2Sat
                                    ? _lastPick.pId === item.pId
                                    : _lastPick.partNumberID === item.partNumberID,
                                  false,
                                  true
                                )}
                                id="btnCompleteItem"
                                data-testid="active-view-complete-item"
                                text="Complete Item"
                                customClass="secondaryButton"
                                loading={this.state.loading}
                                disabled={
                                  !(
                                    orderPickedQty !== "" &&
                                    orderPickedQty >= 0 &&
                                    orderPickedQty <= item.orderItemQY
                                  )
                                }
                              />
                              <ADialog
                                id="activeBarCodeValidation"
                                modalProps={this.constructedProps(item, _lastPick)}
                              />
                            </div>
                          </MediaQuery>
                        </div>
                        <div className="controlSectionFooter">
                          <div className="planogramWrapper pb-16">
                            <span className="planoGramLabel">
                              <span className="pogLabel">
                                <strong>POG</strong>
                              </span>
                            </span>
                            <span className="seqLabel">
                              <strong>SEQ</strong>
                            </span>
                          </div>
                        </div>
                        <div className="controlSectionFooter">
                          {this.getPOGSEQByPartNumber(createPickTour, item.partNumberID, item.pId)}
                          {item.invoiceStoreID && (
                            <div className="sat2satInfo">
                              <span>
                                Destination Store: {item.invoiceStoreID.replace(/^0+/, "")}
                              </span>
                              <span>Keep QOH: {item.keepQuantityOnHand || 0}</span>
                            </div>
                          )}
                        </div>
                        <MediaQuery maxWidth={480}>
                          <div className="errMsg">
                            {errors && errors.isCompleteItemError && (
                              <div id="completeItemErrorWrapper">
                                <ErrorMessage message="completing item" />
                              </div>
                            )}
                          </div>
                          <div className="completeButtonWrapper">
                            <OButton
                              onButtonClick={this.onUpdatePickTour.bind(
                                this,
                                item,
                                orderPickedQty,
                                isSat2Sat
                                  ? _lastPick.pId === item.pId
                                  : _lastPick.partNumberID === item.partNumberID,
                                false,
                                true
                              )}
                              id="btnCompleteItem"
                              data-testid="active-view-complete-item"
                              text="Complete Item"
                              type="primary"
                              customClass="btnWrapper"
                              loading={this.state.loading}
                              disabled={
                                !(
                                  orderPickedQty !== "" &&
                                  orderPickedQty >= 0 &&
                                  orderPickedQty <= item.orderItemQY
                                )
                              }
                            />
                            <ADialog
                              id="activeBarCodeValidation"
                              modalProps={this.constructedProps(item, _lastPick)}
                            />
                          </div>
                        </MediaQuery>
                      </div>
                    </div>
                  </div>
                )}
                {(isSat2Sat
                  ? _firstPick.pId !== item.pId
                  : _firstPick.partNumberID !== item.partNumberID) && (
                  <div key={"inactiveInactiveOrders." + index} className="unSelectedHeadingSection">
                    <div className="alignedItems">
                      <div>
                        <span className="labelPartId">Part #:</span>
                        <span className="labelPartIdValue">{item.partNumberID}</span>
                        <span className="noOfItems">
                          <span>{`${
                            index + size(completedPickTourList) + 1
                          } of ${_totalCount} Items`}</span>
                        </span>
                      </div>
                      <div className="quantityPickWrapper">
                        <div>
                          <span className="sku">SKU: </span>
                          <span className="skuValue">{item.itemID.replace(/^0+/, "")}</span>
                        </div>
                        <div className="quantityRequested">
                          <span className="labelQuantityPick">Qty. Requested:</span>
                          <span className="labelQuantityPickValue">{item.orderItemQY}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  }
}
