import React, { Component } from "react";
import { FormControl } from "react-bootstrap";

export default class TextInput extends Component {
  handleChange(e) {
    // onchange event for custom input
    if (this.props.handleInputChange) this.props.handleInputChange(e);
  }

  render() {
    const { placeholder, size, type, css, id, value, readOnly } = this.props;
    return (
      <FormControl
        id={id}
        className={`${css} formControl`}
        bsSize={size}
        type={type}
        value={value}
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}
