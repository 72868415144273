import EventLogger from "@az/whispers-js";
import { get } from "lodash";
import { auth } from "../components/AuthContext";
import { store } from "../store";
import { ENVS } from "./env";

const { env } = window;

async function tokenCallback() {
  const tokens = await auth.getTokens();
  if (!tokens) {
    return { accessToken: "", refreshToken: "" };
  }

  const { accessToken, refreshToken } = tokens;
  if (accessToken && refreshToken) {
    return { accessToken, refreshToken };
  } else {
    return { accessToken: "", refreshToken: "" };
  }
}

// This method handles converting our deployment environments into the APIGW environment that the whispersJS package needs; make sure that the values here correspond with the APIGW that is configured in env.js
function convertEnv(env) {
  switch (env) {
    case ENVS.dev:
      return "dv";
    case ENVS.prod:
    case ENVS.beta:
      return "pr-ext";
    case ENVS.stagingInternal:
      return "st";
    case ENVS.stagingExternal:
    case ENVS.stagingBeta:
      return "st-ext";
    case ENVS.qa:
      return env;
    default:
      return "dv";
  }
}

const logger = new EventLogger(
  "Picking-" + convertEnv(env.ENV),
  convertEnv(env.ENV),
  null,
  null,
  tokenCallback
);

const eventLogger = {
  infoWithMetadata: (message, body) => {
    const state = store.getState();
    const storeId = get(state, "store.storeId");
    const uid = get(state, "user.userDetails.claims.uid");
    const cn = get(state, "user.userDetails.claims.cn");

    logger.info(message, JSON.stringify({ ...JSON.parse(body), storeId, uid, cn }));
  },
  errorWithMetadata: (message, body, type) => {
    //We can pass in specific type of errors for better filtering in the logs, ie) crash, request fail
    const state = store.getState();
    const storeId = get(state, "store.storeId");
    const uid = get(state, "user.userDetails.claims.uid");
    const cn = get(state, "user.userDetails.claims.cn");

    logger.error(message, JSON.stringify({ type, ...JSON.parse(body), storeId, uid, cn }));
  }
};

export default eventLogger;
