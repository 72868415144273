export const utcFormat = () => new Date();

export const formatTime = (dateTimeString) => {
  const parsedDate = new Date(dateTimeString);
  var hours = parsedDate.getHours();
  var minutes = parsedDate.getMinutes();
  var amPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + amPm;
  return strTime;
};

export const formatDateTime = (dateTimeString) => {
  var date = new Date(dateTimeString);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  if (month.toString().length == 1) {
    month = "0" + month;
  }
  if (day.toString().length == 1) {
    day = "0" + day;
  }

  var hours = date.getHours();
  var minutes = date.getMinutes();
  var amPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var timeWithAmPm = hours + ":" + minutes + " " + amPm;

  var dateTime = month + "/" + day + "/" + year + " " + timeWithAmPm;
  return dateTime;
};
