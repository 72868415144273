import * as types from "../constants/actionTypes";
export const serviceUnavailableReducer = (state = { isServiceUnavailable: false }, action) => {
  switch (action.type) {
    case types.SET_SERVICE_UN_AVAILABLE: {
      return Object.assign({}, state, {
        isServiceUnavailable: true
      });
    }
    case types.RE_SET_SERVICE_UN_AVAILABLE: {
      return Object.assign({}, state, {
        isServiceUnavailable: false
      });
    }
    default:
      return state;
  }
};
