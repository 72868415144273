import { some, size, reject, concat, flattenDepth, omit, filter, find } from "lodash";
import { utcFormat } from "../utils/azday";
import fetcher from "../utils/fetcher";
import {
  GET_ORDERS,
  POST_PICK_TOUR,
  POST_UPDATE_PICK_TOUR,
  POST_UPDATE_PICK_TOUR_ALL,
  PRINT_PICKTOUR,
  SKIP_URL_STORE_ID,
  FORWARD_URL,
  CONTINUE_STORE_URL,
  POST_TIMEOUT,
  RETRY_URL
} from "../constants/URL";
import * as types from "../constants/actionTypes";
import eventLogger from "../utils/eventLogger";

/* Set or reset the service unavailability flag based on status code return by API for 503 error */

const retryFinalization = (pickTour) => {
  return (dispatch) => {
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    eventLogger.infoWithMetadata("Retrying Finalization for Picktour", JSON.stringify(pickTour));
    return fetcher.post(RETRY_URL, pickTour).finally(() => {
      return dispatch({ type: types.API_IN_PROGRESS, flag: false });
    });
  };
};
const autoSavePickTour = (pickTour) => {
  return (dispatch) => {
    eventLogger.infoWithMetadata("Autosaving Picktour", JSON.stringify(pickTour));
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    return fetcher.post(POST_TIMEOUT, pickTour).finally(() => {
      dispatch({ type: types.API_IN_PROGRESS, flag: false });
    });
  };
};
/* call the print api by passing the print object */
const printPickTour = (createPickTour) => {
  eventLogger.infoWithMetadata("PRINT_PICKTOUR body: ", JSON.stringify(createPickTour));
  return (dispatch) => {
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    return fetcher
      .post(PRINT_PICKTOUR, createPickTour)
      .then(({ data }) => {
        dispatch({ type: types.E_PRINT, flag: false });
        return dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
      })
      .catch((error) => {
        eventLogger.infoWithMetadata("PRINT_PICKTOUR catch: ", JSON.stringify(error));
        dispatch({ type: types.E_PRINT, flag: true });
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
      });
  };
};
/* GetOrders: Will return the list of orders */
const getOrders = (storeId, empId, isFrom, isFromRefresh = false) => {
  eventLogger.infoWithMetadata("Calling Get Orders", JSON.stringify({ storeId, empId, isFrom }));
  return (dispatch) => {
    const handleError = () => {
      dispatch({ type: types.E_GET_ORDERS, flag: true });
      if (isFrom) {
        dispatch({ type: types.E_REFRESH_GET_ORDERS, flag: true });
      }
    };
    /* Set the isLoading to true to show the loader */
    dispatch({ type: types.SET_LOADER, isLoading: true });
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    if (isFromRefresh) {
      dispatch({ type: types.SET_LOADER_REFRESH, isLoadingRefresh: true });
    }

    return fetcher
      .get(GET_ORDERS + storeId + "/" + empId)
      .then(({ data }) => {
        const handleSuccess = () => {
          /* Preserve the orders in ordering reducer store */
          dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
          // remove the skipped items from redux
          dispatch({
            type: types.REMOVE_SKIPPED_ITEM,
            payload: []
          });
          // remove the completed items from redux
          dispatch({
            type: types.STORE_SKIP_COMPLETEDLIST,
            payload: []
          });
          // navigate to pending orders screen
          dispatch({ type: types.UPDATE_STEP, step: 1 });
          dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
          // update store with the orders fetched from the server
          // dispatch({ type: types.UPDATE_IS_SAVE_PICK_TOUR, flag: false });
          if (isFrom) {
            dispatch({ type: types.E_REFRESH_GET_ORDERS, flag: false });
          }
          dispatch({ type: types.E_GET_ORDERS, flag: false });
          dispatch({
            type: types.GET_ORDERS,
            payload: data
          });
        };
        if (
          data &&
          ((data.orders && data.orders.length > 0) || (data.pickTours && data.pickTours.length > 0))
        ) {
          handleSuccess();
        } else if (data && data.errors && data.errors.length > 0) {
          handleError();
        } else {
          // If there is an empty response that means there are no orders; the onSuccess code must still run
          handleSuccess();
        }
      })
      .catch((error) => {
        eventLogger.infoWithMetadata(
          "Error from GET_ORDERS",
          JSON.stringify({ ...error, storeId, empId })
        );
        handleError();
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
        dispatch({ type: types.SET_LOADER, isLoading: false });
        if (isFromRefresh) {
          dispatch({ type: types.SET_LOADER_REFRESH, isLoadingRefresh: false });
        }
      });
  };
};

/* StartPickTour: create pick by passing the constructed pick tour object from grouping them based on the part number */
const startPickTour = (pickTour) => {
  return (dispatch) => {
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    eventLogger.infoWithMetadata("POST_PICK_TOUR", JSON.stringify(pickTour));
    return fetcher
      .post(POST_PICK_TOUR, pickTour)
      .then(({ data }) => {
        if (data && data.pickTour) {
          dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
          // navigate to Active Orders screen
          dispatch({ type: types.UPDATE_STEP, step: 2 });
          dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
          return dispatch({ type: types.POST_PICK_TOUR, payload: data.pickTour });
        } else if (data && data.errors && data.errors.length > 0) {
          // TODO: Handle errors from array here
          dispatch({ type: types.E_POST_PICK_TOUR, flag: true });
        }
      })
      .catch((error) => {
        eventLogger.infoWithMetadata("POST_PICK_TOUR error:", JSON.stringify(error));
        dispatch({ type: types.E_POST_PICK_TOUR, flag: true });
        throw new Error(error);
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
      });
  };
};

const onUpdatePickTour = (
  pickTour,
  activePickTour,
  totalCount,
  completedPickTour,
  isLastRecord = false,
  skippedPickTours,
  createPickTour
) => {
  let _uniqPickTourList = pickTour.pickTourList;
  // flag holds whether the picktour contains any skip items
  const isSkipExist = some(_uniqPickTourList, ["isSkippedItemFL", "Y"]);
  const isSat2Sat = _uniqPickTourList.some((item) => item.pickListTypeCD === "SAT2SAT");
  const {
    assignedPickerEmployeeID,
    createTS,
    createUserID,
    instanceID,
    pickTourUID,
    tasks,
    isUPCValidationScanRequiredFL
  } = createPickTour;
  // construct request body based on the orders is skipped or last item in the orders

  let requestBody =
    isLastRecord && !isSkipExist && size(skippedPickTours) === 0
      ? {
          assignedPickerEmployeeID,
          isUPCValidationScanRequiredFL,
          createTS,
          createUserID,
          instanceID,
          pickTourList: pickTour.pickTourList,
          pickTourStatusCD: isLastRecord ? "CLOSED" : "",
          pickTourSubStatusCD: isLastRecord ? "FILLED" : "",
          pickTourUID,
          tasks,
          lastMainTainTS: utcFormat()
        }
      : _uniqPickTourList;

  let url =
    isLastRecord && !isSkipExist && size(skippedPickTours) === 0
      ? POST_UPDATE_PICK_TOUR_ALL
      : POST_UPDATE_PICK_TOUR;
  return (dispatch) => {
    //ASHWIN - RIGHT HERE, I think this is happning too slow. The other thing is making API calls too fast for this to block.
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    //ASHWIN - Added duplicate API call for testing

    eventLogger.infoWithMetadata(
      `Calling orderingActions onUpdatePickTour first request (With Dispatch)`,
      JSON.stringify({
        timestamp: new Date().toUTCString(),
        requestBody: requestBody
      })
    );

    const promise = fetcher
      .post(url, requestBody)
      .then((data) => {
        eventLogger.infoWithMetadata(`Posted pick tour update to ${url}`, JSON.stringify(data));
        dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
        if (size(pickTour.pickTourList) > 0) {
          let _activePickTour = Object.assign([], activePickTour);
          let _newCompletedPicktour = _activePickTour.map((item) => {
            if (
              isSat2Sat
                ? item.pId === pickTour.pickTourList[0].replenishmentOrderHeaderUID
                : item.partNumberID === pickTour.pickTourList[0].partNumberID
            ) {
              return Object.assign({}, item, { isCompleted: true });
            }
            return Object.assign({}, item);
          });
          let completedItemList = Object.assign([], completedPickTour);
          completedItemList.push(find(_newCompletedPicktour, ["isCompleted", true]));
          let _rejectedList = reject(_activePickTour, (item) =>
            isSat2Sat
              ? item.pId === pickTour.pickTourList[0].replenishmentOrderHeaderUID
              : item.partNumberID === pickTour.pickTourList[0].partNumberID
          );
          /* store here the skipped tours */
          if (isSkipExist) {
            storeSkippedPickTours(skippedPickTours, pickTour, dispatch);
          }
          if (isLastRecord) {
            if (isSkipExist || size(skippedPickTours) > 0) {
              dispatch({ type: types.UPDATE_STEP, step: 3 });
            } else {
              dispatch({ type: types.UPDATE_STEP, step: 4 });
            }
          }
          /* Clear service error flag */
          dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
          /* store only  the active orders to show on active orders page */
          return dispatch({
            type: types.STORE_ACTIVE_PICK_TOUR,
            payload: _rejectedList,
            totalCount: totalCount - 1,
            completedItemList: size(_rejectedList) > 0 ? completedItemList : []
          });
        }
      })
      .catch((error) => {
        eventLogger.infoWithMetadata(
          `Posted pick tour update to ${url} error:`,
          JSON.stringify(error)
        );
        /* Enable error message notification */
        if (url === POST_UPDATE_PICK_TOUR_ALL && error.response && error.response.status === 409) {
          throw error;
        } else {
          dispatch({ type: types.E_COMPLETE_ITEM, flag: true });
          dispatch({ type: types.E_PART_SUBMIT_AND_COMPLETE, flag: true });
        }
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
      });
    return promise;
  };
};

const updateSkipItemsForStoreId = (pickTour, skipCompletedList) => {
  let newPickTour = Object.assign({}, pickTour, {
    pickTourList: concat(pickTour.pickTourList, flattenDepth(skipCompletedList, 1)),
    pickTourStatusCD: "CLOSED",
    pickTourSubStatusCD: "PARTFILL"
  });

  eventLogger.infoWithMetadata("SKIP_URL_STORE_ID body: ", JSON.stringify(newPickTour));
  return (dispatch) => {
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    return fetcher
      .post(SKIP_URL_STORE_ID, newPickTour)
      .then(({ data }) => {
        if (data && data.skippedItemDetails) {
          const isSat2Sat = newPickTour.pickTourList.some(
            (pickTourItem) => pickTourItem.pickListTypeCD === "SAT2SAT"
          );
          if (isSat2Sat) {
            dispatch({
              type: types.STORE_SKIPPED_ITEMS_STORE_DETAILS,
              payload: null,
              noRecevingStore: newPickTour,
              isSat2Sat
            });
          } else {
            const {
              skippedItemDetails: { receivingStoreId = null }
            } = data;
            if (receivingStoreId) {
              const _response = Object.assign({}, data.skippedItemDetails, {
                pickObj: newPickTour
              });
              dispatch({
                type: types.STORE_SKIPPED_ITEMS_STORE_DETAILS,
                payload: _response,
                noRecevingStore: null,
                isSat2Sat
              });
            } else {
              dispatch({
                type: types.STORE_SKIPPED_ITEMS_STORE_DETAILS,
                payload: null,
                noRecevingStore: newPickTour,
                isSat2Sat
              });
            }
          }
        }
      })
      .catch((error) => {
        eventLogger.infoWithMetadata("SKIP_URL_STORE_ID catch: ", JSON.stringify(error));
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
      });
  };
};
// continue store action will triggered for the Transfer deliveryMethod order */
const continueStoreSubmit = (pickTour) => {
  return (dispatch) => {
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    let newPickTour = Object.assign({}, pickTour, {
      deliveryGroupStatusCD: "CLOSED",
      pickTourStatusCD: "CLOSED",
      pickTourSubStatusCD: "PARTFILL"
    });
    eventLogger.infoWithMetadata("Continue Store Request", JSON.stringify(newPickTour));
    return fetcher
      .post(CONTINUE_STORE_URL, newPickTour)
      .then(({ data }) => {
        // navigate to pending orders screen
        dispatch({ type: types.UPDATE_STEP, step: 1 });
        dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
        // dispatch({ type: types.E_CONTINUE, flag: false });
        return dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
      })
      .catch((error) => {
        eventLogger.infoWithMetadata(
          "Error from CONTINUE_STORE",
          JSON.stringify({ ...newPickTour, ...error })
        );
        dispatch({ type: types.E_CONTINUE, flag: true });
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
      });
  };
};
// forwardOrder store action will triggered for the Transfer deliveryMethod order if any near by  store is having those orders to fulfill*/
const forwardOrder = (pickTour) => {
  return (dispatch) => {
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    let _requestBody = Object.assign({}, pickTour, {
      pickTourStatusCD: "CLOSED",
      pickTourSubStatusCD: "PARTFILL",
      pickTourList: pickTour.pickTourList.map((item) =>
        Object.assign({}, item, { deliveryMethodCD: "DELIVER" })
      )
    });
    eventLogger.infoWithMetadata("Sending to forward URL", JSON.stringify(_requestBody));
    return fetcher
      .post(FORWARD_URL, _requestBody)
      .then(({ data }) => {
        dispatch({
          type: types.STORE_SKIPPED_ITEMS_STORE_DETAILS,
          payload: null,
          noRecevingStore: null,
          isSat2Sat: null
        });
        dispatch({
          type: types.REMOVE_SKIPPED_ITEM,
          payload: []
        });
        // navigate to complete picktours screen
        dispatch({ type: types.UPDATE_STEP, step: 4 });
        dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
        // dispatch({ type: types.E_FORWARD, flag: false });
        return dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
      })
      .catch((error) => {
        eventLogger.infoWithMetadata("Forward URL error", JSON.stringify(error));
        dispatch({ type: types.E_FORWARD, flag: true });
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
      });
  };
};
const postPickTourUrgent = (pickTour, assignedPickerEmployeeID, customerOrganizationLNM) => {
  return (dispatch) => {
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    let _requestBody = omit(
      Object.assign({}, pickTour, {
        assignedPickerEmployeeID,
        lastMaintainUserID: assignedPickerEmployeeID
      }),
      ["customerOrganizationLNM"]
    );
    eventLogger.infoWithMetadata(
      "Updating Picktour",
      JSON.stringify({ ..._requestBody, assignedPickerEmployeeID })
    );
    return fetcher
      .post(POST_UPDATE_PICK_TOUR_ALL, _requestBody)
      .then((data) => {
        dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
        return dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
      })
      .catch((error) => {
        eventLogger.infoWithMetadata("Update Picktour Error", JSON.stringify(error));
        if (error.response && error.response.status === 409) {
          throw error;
        } else {
          dispatch({ type: types.E_POST_PICK_TOUR, flag: true });
          throw new Error(error);
        }
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
      });
  };
};
// noStoreUpdatePickTour will trigger for the Transfer deliveryMethod order if any nearby store is unable to fulfill those orders or for sat2sat pick tours
const noStoreUpdatePickTour = (pickTour) => {
  return (dispatch) => {
    dispatch({ type: types.API_IN_PROGRESS, flag: true });
    let _requestBody = Object.assign({}, pickTour, {
      pickTourStatusCD: "CLOSED",
      pickTourSubStatusCD: "PARTFILL"
    });

    eventLogger.infoWithMetadata("No Store Updating Picktour", JSON.stringify(_requestBody));
    return fetcher
      .post(POST_UPDATE_PICK_TOUR_ALL, _requestBody)
      .then(({ data }) => {
        dispatch({
          type: types.STORE_SKIPPED_ITEMS_STORE_DETAILS,
          payload: null,
          noRecevingStore: null,
          isSat2Sat: null
        });
        dispatch({
          type: types.REMOVE_SKIPPED_ITEM,
          payload: []
        });
        // navigate to complete picktours screen
        dispatch({ type: types.UPDATE_STEP, step: 4 });
        dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });

        return dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
      })
      .catch((error) => {
        eventLogger.infoWithMetadata("No Store Update Picktour Error", JSON.stringify(error));
        if (error.response && error.response.status === 409) {
          throw error;
        } else {
          dispatch({ type: types.E_COMPLETE_ITEM, flag: true });
          dispatch({ type: types.E_PART_SUBMIT_AND_COMPLETE, flag: true });
        }
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
      });
  };
};
// update skip tour
const updateSkipPickTour = (
  pickTour,
  skippedPickTours,
  selectedOrder,
  user,
  isLastRecord = false,
  skipCompletedList,
  createPickTour
) => {
  pickTour.pickTourList[0].verifiedManuallyKeyedEmployeeID = user.userDetails.claims.uid;
  pickTour.pickTourList[0].verifiedSkippedEmployeeID = user.userDetails.claims.uid;
  let _url = isLastRecord ? POST_UPDATE_PICK_TOUR_ALL : POST_UPDATE_PICK_TOUR;
  let _uniqPickTourList = pickTour.pickTourList;
  const isSkipExist = some(_uniqPickTourList, ["isSkippedItemFL", "Y"]);
  const {
    assignedPickerEmployeeID,
    createTS,
    createUserID,
    instanceID,
    lastMaintainTS,
    lastMaintainUserID,
    pickListTypeCD,
    pickTourUID,
    tasks
  } = createPickTour;
  let requestBody = isLastRecord
    ? {
        pickTourList: pickTour.pickTourList,
        pickTourStatusCD: "CLOSED",
        pickTourSubStatusCD: !isSkipExist && size(skipCompletedList) === 0 ? "FILLED" : "PARTFILL",
        assignedPickerEmployeeID,
        createTS,
        createUserID,
        instanceID,
        lastMaintainTS,
        lastMaintainUserID,
        pickListTypeCD,
        pickTourUID,
        tasks
      }
    : _uniqPickTourList;
  let skipItemsCompletedList = filter(requestBody, ["isSkippedItemFL", "Y"]); // take only the skipped items from the order collection
  if (size(skipItemsCompletedList) > 0) {
    skipCompletedList.push(skipItemsCompletedList);
  }
  return (dispatch) => {
    dispatch({ type: types.API_IN_PROGRESS, flag: true });

    eventLogger.infoWithMetadata(
      `Calling orderingActions updateSkipPickTour first request (With Dispatch)`,
      JSON.stringify({
        timestamp: new Date().toUTCString(),
        requestBody: requestBody
      })
    );

    const promise = fetcher
      .post(_url, requestBody)
      .then(({ data }) => {
        dispatch({
          type: types.STORE_SKIP_COMPLETEDLIST,
          payload: skipCompletedList
        });
        dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
        // navigate to complete picktours screen
        if (isLastRecord) {
          dispatch({ type: types.UPDATE_STEP, step: 4 });
          dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
        }
        dispatch({ type: types.E_COMPLETE_ITEM, flag: false });
        dispatch({ type: types.E_PART_SUBMIT_AND_COMPLETE, flag: false });

        return dispatch(removeSkippedPickTour(skippedPickTours, selectedOrder));
      })
      .catch((error) => {
        if (_url === POST_UPDATE_PICK_TOUR_ALL && error.response && error.response.status === 409) {
          // dispatch({ type: types.UPDATE_STEP, step: 1 });
          // recallOrders(dispatch);
          throw error;
        } else {
          dispatch({ type: types.E_COMPLETE_ITEM, flag: true });
          dispatch({ type: types.E_PART_SUBMIT_AND_COMPLETE, flag: true });
        }
      })
      .finally(() => {
        dispatch({ type: types.API_IN_PROGRESS, flag: false });
      });
    return promise;
  };
};

/* API Calls End */

/* Dispatch Functions */
const clearServiceErrorNotifications = () => {
  return (dispatch) => {
    return dispatch({ type: types.CLEAR_SERVICE_ERROR_MESSAGES });
  };
};

const notifyItemFoundServiceError = () => {
  return (dispatch) => {
    return dispatch({ type: types.E_PART_SUBMIT_AND_COMPLETE, flag: true });
  };
};

const notifyConfirmSkipServiceError = () => {
  return (dispatch) => {
    return dispatch({ type: types.E_SKIP_ITEM, flag: true });
  };
};

/* navigate to the step based on the step number */
const setByStep = (stepNo) => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
    return dispatch({ type: types.UPDATE_STEP, step: stepNo });
  };
};
/* reset the page to show empty while logout happening */
const resetToPendingOrders = () => {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
    return dispatch({ type: types.UPDATE_STEP, step: 0 });
  };
};

const upDateIsSavePicktourFlag = (flag) => {
  return (dispatch) => {
    return dispatch({ type: types.UPDATE_IS_SAVE_PICK_TOUR, flag });
  };
};

/* remove the skipped items from the reducer */
const clearSkippedItems = () => {
  return (dispatch) => {
    return dispatch({
      type: types.CLEAR_SKIPPED_ITEMS,
      payload: []
    });
  };
};

/* store the single selected Urgent order into the store */
const storePickTourList = (pickTourList, totalCount, activePickTourUID, isFromOrdering = false) => {
  return (dispatch) => {
    if (!isFromOrdering) {
      if (size(pickTourList) > 0) {
        dispatch({ type: types.UPDATE_STEP, step: 2 });
      } else {
        dispatch({ type: types.UPDATE_STEP, step: 1 });
      }
      dispatch({ type: types.CLEAR_ALL_ERROR_MESSAGES });
    }
    return dispatch({
      type: types.STORE_ACTIVE_PICK_TOUR,
      payload: pickTourList,
      totalCount: totalCount,
      activePickTourUID: activePickTourUID,
      completedItemList: []
    });
  };
};
// store the skipped items into an collection of redux
const storeSkippedPickTours = (skippedPickTours = [], pickTour) => {
  let itemPicked = 0;
  let itemQy = 0;
  if (pickTour && pickTour.pickTourList.length > 0) {
    pickTour.pickTourList.forEach((item) => {
      itemQy = itemQy + item.orderItemQY;
      itemPicked = itemPicked + item.pickedItemQY;
    });
    if (itemQy > itemPicked) {
      skippedPickTours.push(pickTour);
    }
  }
  return (dispatch) => {
    return dispatch({
      type: types.STORE_SKIPPED_PICK_TOUR,
      payload: skippedPickTours
    });
  };
};
// clear the skip items from the redux store
const removeSkippedPickTour = (skippedPickTours = [], pickTour) => {
  const isSat2Sat = skippedPickTours.some((skippedPickTour) =>
    skippedPickTour.pickTourList.some((pick) => pick.pickListTypeCD === "SAT2SAT")
  );
  const finalSkipped = skippedPickTours.filter((item) =>
    isSat2Sat
      ? item.pickTourList[0].replenishmentOrderHeaderUID !== pickTour.pId
      : item.pickTourList[0].partNumberID !== pickTour.partNumberID
  );
  return (dispatch) => {
    return dispatch({
      type: types.REMOVE_SKIPPED_ITEM,
      payload: finalSkipped
    });
  };
};
// if any items got stipped make it as true else false
const skippedFlag = (isSkipped = false) => {
  return (dispatch) => {
    return dispatch({
      type: types.SKIPPED_FLAG,
      payload: isSkipped
    });
  };
};
/* store the selected orders into store reducer */
const updateSelectedProducts = (selectedProducts) => {
  return (dispatch) => {
    return dispatch({
      type: types.STORE_SELECTED_PRODUCTS,
      payload: selectedProducts
    });
  };
};

const restartPickTour = (pickTour) => {
  return (dispatch) => {
    return dispatch({ type: types.POST_PICK_TOUR, payload: pickTour });
  };
};

let setResetServiceUnavialbility = (error) => {
  console.log("setResetServiceUnavialbility");
  return (dispatch) => {
    console.log("setResetServiceUnavialbility 2", error);
    if (error && error.response && error.response.status === 503) {
      return dispatch({ type: types.SET_SERVICE_UN_AVAILABLE });
    } else {
      return dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
    }
  };
};
/* Set or reset the service unavailability flag based on status code return by API for 503 error */
let setResetServiceUnavialbilityWithDispatch = (error) => {
  return (dispatch) => {
    if (error && error.response && error.response.status === 404) {
      dispatch({ type: types.SET_SERVICE_UN_AVAILABLE });
    } else {
      dispatch({ type: types.RE_SET_SERVICE_UN_AVAILABLE });
    }
  };
};
export {
  /* Dispatch Functions */
  clearServiceErrorNotifications,
  notifyItemFoundServiceError,
  notifyConfirmSkipServiceError,
  setByStep,
  resetToPendingOrders,
  upDateIsSavePicktourFlag,
  clearSkippedItems,
  storePickTourList,
  storeSkippedPickTours,
  removeSkippedPickTour,
  skippedFlag,
  updateSelectedProducts,
  restartPickTour,
  setResetServiceUnavialbility,
  setResetServiceUnavialbilityWithDispatch,
  /* API Actions */
  autoSavePickTour,
  printPickTour,
  getOrders,
  startPickTour,
  onUpdatePickTour,
  updateSkipItemsForStoreId,
  continueStoreSubmit,
  forwardOrder,
  noStoreUpdatePickTour,
  updateSkipPickTour,
  postPickTourUrgent,
  retryFinalization
};
