import * as types from "../constants/actionTypes";

export const inventoryReducer = (
  state = {
    skipCompletedList: [],
    currentStep: 1,
    isSavePickTourActive: false,
    isAPIInProgress: false,
    isLoadingRefresh: false,
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case types.GET_ORDERS:
      return Object.assign({}, state, {
        data: action.payload,
        isLoading: action.isLoading,
        completedItemList: [],
        isPickTourInProgress: false,
        createPickTour: null,
        activePickTour: null
      });
    case types.SET_LOADER:
      return Object.assign({}, state, {
        isLoading: action.isLoading
      });
    case types.SET_LOADER_REFRESH:
      return Object.assign({}, state, {
        isLoadingRefresh: action.isLoadingRefresh
      });
    case types.POST_PICK_TOUR:
      return Object.assign({}, state, {
        createPickTour: action.payload,
        isLoading: action.isLoading,
        isPickTourInProgress: true
      });
    case types.IS_PICK_TOUR_IN_PROGRESS:
      return Object.assign({}, state, {
        isPickTourInProgress: action.flag
      });
    case types.STORE_ACTIVE_PICK_TOUR:
      return Object.assign({}, state, {
        activePickTour: action.payload,
        totalCount: action.totalCount,
        activePickTourUID: action.activePickTourUID,
        completedItemList: action.completedItemList,
        isPickTourInProgress: true
      });
    case types.RESTART_PICK_TOUR:
      return Object.assign({}, state, {
        createPickTour: action.payload,
        isLoading: action.isLoading
      });
    case types.STORE_SKIPPED_PICK_TOUR:
      return Object.assign({}, state, {
        skippedPickTours: action.payload,
        isPickTourInProgress: true
      });
    case types.REMOVE_SKIPPED_ITEM:
      return { ...state, skippedPickTours: action.payload, isPickTourInProgress: true };
    case types.SKIPPED_FLAG:
      return { ...state, isSkipped: action.payload, isPickTourInProgress: true };
    case types.STORE_SELECTED_PRODUCTS:
      return Object.assign({}, state, {
        selectedProducts: action.payload,
        isPickTourInProgress: true
      });
    case types.CLEAR_SKIPPED_ITEMS:
      return Object.assign({}, state, {
        skippedPickTours: action.payload
      });
    case types.STORE_SKIP_COMPLETEDLIST:
      return Object.assign({}, state, {
        skipCompletedList: action.payload
      });
    case types.STORE_SKIPPED_ITEMS_STORE_DETAILS:
      return Object.assign({}, state, {
        skippedStoreDetails: action.payload,
        noRecevingStore: action.noRecevingStore,
        isSat2Sat: action.isSat2Sat
      });
    case types.UPDATE_STEP:
      return Object.assign({}, state, { currentStep: action.step });
    case types.UPDATE_IS_SAVE_PICK_TOUR:
      return Object.assign({}, state, { isSavePickTourActive: action.flag });
    case types.API_IN_PROGRESS:
      return Object.assign({}, state, {
        isAPIInProgress: action.flag
      });
    default:
      return state;
  }
};
