import React, { Component, Fragment } from "react";
/* Functions for re-generate the token if the service gives - (Status 503) or Timedout */
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { size, filter, remove, uniqBy, omit, flattenDepth, some, find, each } from "lodash";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../containers/ErrorFallback";
import { utcFormat } from "../utils/azday";
/* Container for the Active/Skip/Complete Pick Tour steps */
import Ordering from "../components/Ordering";
/* 1. Transfer Store */
import TransferStore from "../components/TransferStore";
/* 2. No Store */
import NoStore from "../components/NoStore";
/* 3. Continue Store */
import ContinueStore from "../components/ContinueStore";
/** 1 - Tranfer Store - Orders are available in the near by store,
    2 - No Store - If no near by store to fulfill the orders,
    3 - Continue Store - Show this notification when the orders are strted picking for nearby store */

/**
 * Screen 1 - Pending Orders
 * Screen 2 - Active Ordering
 * Screen 3 - Skip Item Validation
 * Screen 4 - Complete Pick Tours
 */
/* API Calls */
import {
  postPickTourUrgent,
  getOrders,
  startPickTour,
  onUpdatePickTour,
  storePickTourList,
  restartPickTour,
  storeSkippedPickTours,
  setResetServiceUnavialbility,
  skippedFlag,
  removeSkippedPickTour,
  updateSkipPickTour,
  setResetServiceUnavialbilityWithDispatch,
  printPickTour,
  updateSkipItemsForStoreId,
  forwardOrder,
  noStoreUpdatePickTour,
  continueStoreSubmit,
  setByStep,
  upDateIsSavePicktourFlag,
  notifyItemFoundServiceError,
  notifyConfirmSkipServiceError,
  clearServiceErrorNotifications,
  autoSavePickTour,
  retryFinalization
} from "../actions/orderingActions";
import dingSound from "../assets/audio/ding.mp3";
import eventLogger from "../utils/eventLogger";

/* Redux */
/* Construct picktour object list to pass the created API */
/* Flag - "Y" (whenever the items skipped/or the orders not fulfilled) Else the flag will be "N" */

const utcNow = utcFormat();
const constructSelectedPickTours = (
  selectedPickTours,
  uid,
  createPickTour = null,
  isFromSkip = false,
  isSat2Sat
) => {
  if (createPickTour) {
    const pickTourList = createPickTour && createPickTour.pickTourList;
    const pickTourUID = createPickTour && createPickTour.pickTourUID;
    return (
      selectedPickTours &&
      selectedPickTours.map((item, index) => {
        return {
          commercialDeliveryGroupSequenceNB:
            pickTourList &&
            pickTourList[index] &&
            pickTourList[index].commercialDeliveryGroupSequenceNB,
          commercialSalesOrderDetailLineNB:
            pickTourList &&
            pickTourList[index] &&
            pickTourList[index].commercialSalesOrderDetailLineNB,
          commercialSalesOrderUID:
            pickTourList && pickTourList[index] && pickTourList[index].commercialSalesOrderUID,
          createTS: utcNow,
          createUserID: uid,
          isManuallyKeyedFL: isFromSkip ? "Y" : "N",
          isOrderedItemCanceledFL: "",
          isSkippedItemFL: isFromSkip ? "Y" : "N",
          itemDS: pickTourList && pickTourList[index] && pickTourList[index].itemDescription,
          itemFulfillmentMethodCD: "",
          partNumberID: selectedPickTours[index].partNumberID,
          itemID: pickTourList && pickTourList[index] && pickTourList[index].itemID,
          lastMaintainTS: utcNow,
          lastMaintainUserID: uid,
          orderItemQY: pickTourList && pickTourList[index] && pickTourList[index].orderItemQY,
          pickListTypeCD: "CMMRCL",
          pickTourListDetailLineNB:
            pickTourList && pickTourList[index] && pickTourList[index].pickTourListDetailLineNB,
          pickTourListSequenceNB:
            pickTourList && pickTourList[index] && pickTourList[index].pickTourListSequenceNB,
          pickTourUID,
          pickedItemQY: 0,
          verifiedManuallyKeyedEmployeeID: isFromSkip ? uid : "",
          verifiedSkippedEmployeeID: isFromSkip ? uid : ""
        };
      })
    );
  } else if (isSat2Sat) {
    return (
      selectedPickTours &&
      selectedPickTours.map((item) => {
        return {
          pickListTypeCD: "SAT2SAT",
          replenishmentOrderHeaderUID: item.commercialSalesOrderUID,
          replenishmentOrderLineNB: item.commercialSalesOrderDetailLineNB,
          itemFulfillmentSourceCD: "STORE",
          itemFulfillmentSupplierID: item.itemFulfillmentSupplierID,
          itemID: item.itemID,
          itemDS: item.itemDescription,
          orderItemQY: item.orderItemQY,
          partNumberID: item.partNumberID,
          pickedItemQY: 0
        };
      })
    );
  } else {
    return (
      selectedPickTours &&
      selectedPickTours.map((item) => {
        return {
          commercialDeliveryGroupSequenceNB: item.commercialDeliveryGroupSequenceNB,
          commercialSalesOrderDetailLineNB: item.commercialSalesOrderDetailLineNB,
          commercialSalesOrderUID: item.commercialSalesOrderUID,
          createTS: utcNow,
          createUserID: uid,
          isManuallyKeyedFL: "",
          pickListTypeCD: "CMMRCL",
          isOrderedItemCanceledFL: "",
          isSkippedItemFL: "",
          itemDS: item.itemDescription,
          partNumberID: item.partNumberID,
          itemID: item.itemID,
          lastMaintainTS: utcNow,
          lastMaintainUserID: uid,
          orderItemQY: item.orderItemQY,
          pickTourListDetailLineNB: item.pickTourListDetailLineNB,
          pickTourListSequenceNB: item.pickTourListSequenceNB,
          pickTourUID: "",
          pickedItemQY: 0,
          verifiedManuallyKeyedEmployeeID: "",
          verifiedSkippedEmployeeID: ""
        };
      })
    );
  }
};
/* Construct the data orders to pass /update as request body */
const constructSelectedPickToursForUpdate = (
  pickTours,
  uid,
  createPickTour,
  selectedPickTourItem,
  orderPickedQty,
  isFromSkip = false,
  createUserID,
  isSat2Sat = false
) => {
  const { pickTourUID } = createPickTour;
  let _qty = 0;
  let _totalOrderPickedQty = orderPickedQty;
  return pickTours.map((item, index) => {
    if (item.orderItemQY <= _totalOrderPickedQty) {
      _qty = item.orderItemQY;
      _totalOrderPickedQty = _totalOrderPickedQty - item.orderItemQY; // In total quantity, deduct the quantity the user provided
    } else {
      _qty = _totalOrderPickedQty;
      _totalOrderPickedQty = _totalOrderPickedQty - orderPickedQty; // In total quantity, deduct the quantity the user provided
    }
    if (isSat2Sat) {
      return {
        pickListTypeCD: "SAT2SAT",
        replenishmentOrderHeaderUID: item.replenishmentOrderHeaderUID,
        replenishmentOrderLineNB: item.replenishmentOrderLineNB,
        itemFulfillmentSourceCD: "STORE",
        itemFulfillmentSupplierID: item.itemFulfillmentSupplierID,
        createTS: utcNow,
        createUserID: createUserID !== undefined ? createUserID : uid,
        isManuallyKeyedFL: isFromSkip || _qty < item.orderItemQY ? "Y" : "N",
        isOrderedItemCanceledFL: "",
        isSkippedItemFL: isFromSkip || _qty < item.orderItemQY ? "Y" : "N",
        itemDS: item.itemDS,
        partNumberID: selectedPickTourItem.partNumberID,
        itemID: item.itemID,
        lastMaintainTS: utcNow,
        lastMaintainUserID: uid,
        orderItemQY: item.orderItemQY,
        pickTourListDetailLineNB: item.pickTourListDetailLineNB,
        pickTourListSequenceNB: item.pickTourListSequenceNB,
        pickTourUID,
        pickedItemQY: isFromSkip ? 0 : _qty >= 0 ? _qty : 0,
        verifiedManuallyKeyedEmployeeID: "",
        verifiedSkippedEmployeeID: "",
        deliveryMethodCD: "",
        itemFulfillmentMethodCD: item.itemFulfillmentMethodCD
      };
    } else {
      return {
        pickListTypeCD: "CMMRCL",
        commercialDeliveryGroupSequenceNB: item.commercialDeliveryGroupSequenceNB,
        commercialSalesOrderDetailLineNB: item.commercialSalesOrderDetailLineNB,
        commercialSalesOrderUID: item.commercialSalesOrderUID,
        createTS: utcNow,
        createUserID: createUserID !== undefined ? createUserID : uid,
        isManuallyKeyedFL: isFromSkip || _qty < item.orderItemQY ? "Y" : "N",
        isOrderedItemCanceledFL: "",
        isSkippedItemFL: isFromSkip || _qty < item.orderItemQY ? "Y" : "N",
        itemDS: item.itemDS,
        partNumberID: selectedPickTourItem.partNumberID,
        itemID: item.itemID,
        lastMaintainTS: utcNow,
        lastMaintainUserID: uid,
        orderItemQY: item.orderItemQY,
        pickTourListDetailLineNB: item.pickTourListDetailLineNB,
        pickTourListSequenceNB: item.pickTourListSequenceNB,
        pickTourUID,
        pickedItemQY: isFromSkip ? 0 : _qty >= 0 ? _qty : 0,
        verifiedManuallyKeyedEmployeeID: "",
        verifiedSkippedEmployeeID: "",
        deliveryMethodCD: "",
        itemFulfillmentMethodCD: item.itemFulfillmentMethodCD
      };
    }
  });
};
const TOT_TIMER = 300000;

let currentTimer;
export class OrderingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isItemsSelected: false,
      transferStore: false,
      noStore: false,
      continueStore: false,
      selectedProducts: null
    };

    this.clearServiceErrorNotifications();

    this.ordersLoaded = false;
    this.newOrderAudibleLoaded = false;
    this.audio = new Audio(dingSound);

    this.audibleQueue = 0;

    this.audio.addEventListener("ended", () => {
      this.audio.currentTime = 0;
      if (--this.audibleQueue > 0) this.audio.play();
    });
    this.audio.addEventListener("loadeddata", () => {
      this.newOrderAudibleLoaded = true;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /* Whenever the user props got modified, call the getOrders to fetch new orders/picktours */
    if (
      this.props &&
      nextProps &&
      this.props.user &&
      nextProps.user &&
      this.props.user.userDetails !== nextProps.user.userDetails
    ) {
      const { storeId, isPickTourInProgress } = nextProps;
      if (storeId && !isPickTourInProgress) {
        this.getOrders();
      }
    }
    if (nextProps && (nextProps.currentStep === 2 || nextProps.currentStep === 3)) {
      this.stopTimer();
    }
  }
  /* Generic method to get Orders */
  getOrders = (isFrom = false, isFromRefresh = false) => {
    const { user, storeId } = this.props;
    const { userDetails } = user;
    if (storeId && userDetails) {
      const uid = userDetails && userDetails.claims && userDetails.claims.uid;
      this.setState({ isItemsSelected: false });
      return this.props.getOrders(storeId, uid, isFrom, isFromRefresh);
    }
  };
  postPickTourUrgent(pickTour) {
    const { user } = this.props;
    const { userDetails } = user;
    const uid = userDetails && userDetails.claims && userDetails.claims.uid;
    return this.props
      .postPickTourUrgent(pickTour, uid)
      .then(() => {
        return;
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 409) {
          this.setState(
            {
              isItemsSelected: false,
              transferStore: false,
              noStore: false,
              continueStore: false,
              selectedProducts: null
            },
            () => this.getOrders()
          );
        }
        throw new Error(error);
      });
  }
  /* On Start pick tour trigger an API action and pass the picktour list of objects */
  startPickTour(selectedProducts, isFromPickTour = false) {
    // Set the isPickTourStarted flag True, So CancelPickTour will be enabled
    const { data, user } = this.props;
    /* From the list of orders pick the selected item by comapring the salesOrderUId and group sequence number */
    let _selectedPickTours =
      !isFromPickTour &&
      selectedProducts.map((item) => {
        return find(
          data.orders,
          (pickItem) =>
            pickItem.commercialDeliveryGroupSequenceNB === item.sequenceNB &&
            pickItem.commercialSalesOrderUID === item.pId
        );
      });
    const isSat2Sat = selectedProducts.some(
      (product) =>
        product.deliveryGroupInfo &&
        product.deliveryGroupInfo.some((dgInfo) => dgInfo.deliveryGroupDetailTypeCD === "SAT2SAT")
    );
    const selectdType = find(selectedProducts, function (o) {
      return o.isUPCValidationScanRequiredFL === "1";
    });
    if (user && user.userDetails && user.userDetails.claims) {
      const { uid } = user.userDetails.claims;
      const _deliveryGroupDetails =
        !isFromPickTour &&
        _selectedPickTours.map((item) => {
          if (item && item.deliveryGroupDetails) {
            return item.deliveryGroupDetails.map((deliveryGroupDetailsItem) => ({
              ...deliveryGroupDetailsItem,
              itemFulfillmentSupplierID: item.fulfillmentStoreID
            }));
          }
          return [];
        });

      // Convert to same level of array
      const _pickTourList =
        !isFromPickTour &&
        constructSelectedPickTours(
          flattenDepth(_deliveryGroupDetails, 1),
          uid,
          null,
          false,
          isSat2Sat
        );
      /* if the picktour is selected the request body should be a
      single selected object or it will be array of picktours */

      const pickTour = isFromPickTour
        ? selectedProducts
        : {
            assignedPickerEmployeeID: uid,
            createTS: utcNow,
            createUserID: uid,
            instanceID: "",
            lastMainTainTS: utcNow,
            lastMaintainUserID: uid,
            pickTourList: _pickTourList,
            pickTourStatusCD: "",
            pickTourSubStatusCD: "",
            pickTourUID: "",
            tasks: "",
            isUPCValidationScanRequiredFL: selectdType ? "1" : "0"
          };
      // Call /create - API
      return this.props.startPickTour(pickTour);
    }
  }
  /* Store pick tour orders in redux */
  constructActivePickTour(pickTourList, totalCount, pickTourUID, isFromOrdering = false) {
    this.props.storePickTourList(pickTourList, totalCount, pickTourUID, isFromOrdering);
  }

  restartPickTour(pickTour) {
    localStorage.setItem("isPickTourStarted", true);
    const { user, createPickTour } = this.props;
    let uid = "";
    if (user && user.userDetails && user.userDetails.claims && createPickTour) {
      uid = user.userDetails.claims.uid;
    }
    pickTour.assignedPickerEmployeeID = uid;
    pickTour.lastMaintainUserID = uid;
    // Update LastMaintainUserId and lastMaintainedTS
    each(pickTour.pickTourList, (item) => {
      item.lastMaintainUserID = uid;
      item.lastMaintainTS = utcFormat();
    });
    this.props.restartPickTour(pickTour);
  }

  // Call /update - API
  onUpdatePickTour(
    selectedPickTourItem,
    orderPickedQty,
    selectedProducts,
    selectedPickTour,
    isLastRecord,
    isFromSkip = false,
    isSat2Sat = false
  ) {
    const {
      createPickTour,
      user,
      activePickTour,
      totalCount,
      completedPickTourList,
      skippedPickTours
    } = this.props;
    const { userDetails } = user;
    const uid = userDetails && userDetails.claims && userDetails.claims.uid;
    let _createPickTour = Object.assign({}, createPickTour, {
      assignedPickerEmployeeID: uid
    });
    if (orderPickedQty || orderPickedQty === 0) {
      var _selectedPickTours;
      if (selectedPickTour && selectedPickTour.pickTourUID) {
        _selectedPickTours = remove(
          selectedPickTour.pickTourList &&
            selectedPickTour.pickTourList.map((item) => {
              return find(
                _createPickTour.pickTourList,
                (pickItem) =>
                  (isSat2Sat
                    ? pickItem.replenishmentOrderHeaderUID === item.replenishmentOrderHeaderUID
                    : pickItem.commercialDeliveryGroupSequenceNB ===
                        item.commercialDeliveryGroupSequenceNB &&
                      pickItem.commercialSalesOrderUID === item.commercialSalesOrderUID) &&
                  pickItem.partNumberID === selectedPickTourItem.partNumberID
              );
            }),
          undefined
        );
      } else if (_createPickTour) {
        _selectedPickTours = remove(
          selectedProducts.map((item) => {
            return find(
              _createPickTour.pickTourList,
              (pickItem) =>
                (isSat2Sat
                  ? pickItem.replenishmentOrderHeaderUID === item.pId
                  : pickItem.commercialDeliveryGroupSequenceNB === item.sequenceNB &&
                    pickItem.commercialSalesOrderUID === item.pId) &&
                pickItem.partNumberID === selectedPickTourItem.partNumberID
            );
          }),
          undefined
        );
      }
      let _fixedPickTours = _createPickTour
        ? filter(_createPickTour.pickTourList, (pickItem) =>
            isSat2Sat
              ? pickItem.replenishmentOrderHeaderUID === selectedPickTourItem.pId
              : pickItem.partNumberID === selectedPickTourItem.partNumberID
          )
        : [];
      if (user && user.userDetails && user.userDetails.claims && _createPickTour) {
        const { uid } = user.userDetails.claims;
        const _selectedItem = constructSelectedPickToursForUpdate(
          _fixedPickTours,
          uid,
          _createPickTour,
          selectedPickTourItem,
          orderPickedQty,
          isFromSkip,
          selectedPickTour.createUserID,
          isSat2Sat
        );
        const {
          instanceID,
          tasks,
          pickTourUID,
          pickListTypeCD,
          pickTourStatusCD,
          pickTourSubStatusCD
        } = _createPickTour;
        const pickTour = {
          assignedPickerEmployeeID: uid,
          createTS: utcNow,
          createUserID: selectedPickTour.pickTourUID ? selectedPickTour.createUserID : uid,
          instanceID: instanceID,
          lastMainTainTS: utcNow,
          lastMaintainUserID: uid,
          pickListTypeCD: pickListTypeCD,
          pickTourList:
            size(_selectedPickTours) > 1
              ? _selectedItem
              : uniqBy(_selectedItem, (item) => {
                  return [item.partNumberID, item.commercialSalesOrderUID].join();
                }),
          pickTourStatusCD: pickTourStatusCD,
          pickTourSubStatusCD: pickTourSubStatusCD,
          pickTourUID: pickTourUID,
          tasks: tasks
        };
        /* Call pick/update if the selected item is last order else call item/update - API */
        return this.props
          .onUpdatePickTour(
            pickTour,
            activePickTour,
            totalCount,
            completedPickTourList,
            isLastRecord,
            skippedPickTours,
            _createPickTour
          )
          .then(() => this.stopTimer())
          .catch((error) => {
            if (error && error.response && error.response.status === 409) {
              this.setState(
                {
                  isItemsSelected: false,
                  transferStore: false,
                  noStore: false,
                  continueStore: false,
                  selectedProducts: null
                },
                () => this.getOrders()
              );
            }
          });
      }
    }
    return undefined;
  }
  // whenever the service is up reset the service unavailability flag to show the ordering
  setResetServiceUnavialbilityWithDispatch(error) {
    if (this.props.setResetServiceUnavialbilityWithDispatch) {
      this.props.setResetServiceUnavialbilityWithDispatch(error);
    }
  }
  // whenever the service is down set the service unavailability flag to show the un available screen
  setResetServiceUnavialbility(error) {
    if (this.props.setResetServiceUnavialbilityWithDispatch) {
      this.props.setResetServiceUnavialbility(error);
    }
  }
  onSelectedPickTour(selectedItemsCount, selectedProducts) {
    this.setState({ isItemsSelected: selectedItemsCount > 0, selectedProducts });
  }

  startTimer() {
    const { currentStep } = this.props;
    const that = this;
    if (currentStep === 2 || currentStep === 3) {
      currentTimer = setTimeout(function () {
        that.onIdle();
      }, TOT_TIMER);
    } else {
      currentTimer = clearTimeout(currentTimer);
    }
  }
  stopTimer() {
    const { currentStep } = this.props;
    if (currentStep === 2 || currentStep === 3) {
      clearTimeout(currentTimer);
    }
    this.startTimer();
  }

  currentTimer = [];
  onIdle() {
    const { createPickTour } = this.props;
    this.props.autoSavePickTour(createPickTour).then(() => {
      this.getOrders().then(() => {
        this.setState(
          {
            isItemsSelected: false,
            transferStore: false,
            noStore: false,
            continueStore: false,
            selectedProducts: null
          },
          () => {
            this.stopTimer();
          }
        );
      });
    });
  }
  onRetryFinalization = (pickTour) => {
    const { user } = this.props;
    const { userDetails } = user;
    const uid = userDetails && userDetails.claims && userDetails.claims.uid;
    const _pickTour = Object.assign({}, pickTour, {
      lastMaintainUserID: uid
    });
    this.props.retryFinalization(_pickTour).then(() => {
      this.getOrders();
    });
  };
  /* Call the get orders for each 60s if the pick tour process is not started */
  componentDidMount() {
    // TODO: do we need this extra 60s delay?
    this.automaticOrdersRefreshDelayTimeout = setTimeout(() => {
      // TODO: verify if we need to refresh every 60s. possible solutions:
      // - push notification from the server when new order comes in (to be
      //   handled by ServiceWorker)
      // - refresh on browser tab focus
      // - refresh every 5 minutes or so
      // - measure inactivity time and report back to analytics
      this.automaticOrdersRefreshInterval = setInterval(() => {
        if (this.props && this.props.user) {
          const { isPickTourInProgress } = this.props;
          if (!isPickTourInProgress && !this.state.isItemsSelected) {
            eventLogger.infoWithMetadata(
              "Automatically refreshing for new orders",
              JSON.stringify({})
            );
            // Sending true to set isFromRefresh parameter
            this.getOrders(false, true);
          }
        }
      }, 60000);
    }, 60000);
  }

  componentWillUnmount() {
    clearTimeout(this.automaticOrdersRefreshDelayTimeout);
    clearInterval(this.automaticOrdersRefreshInterval);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data && this.props.data) {
      if (this.ordersLoaded) {
        const prevOrderIDs = prevProps.data.orders.map((order) => order.commercialSalesOrderUID);
        const currOrderIDs = this.props.data.orders.map((order) => order.commercialSalesOrderUID);

        this.checkNewOrders(prevOrderIDs, currOrderIDs);
      } else if (this.props.data.orders.length > 0) {
        this.ordersLoaded = true;

        if (this.newOrderAudibleLoaded) {
          this.audio.play();
        } else {
          this.audibleQueue++;
        }
      }
    }

    if (!prevProps.user.userDetails && this.props.user && this.props.user.userDetails) {
      this.getOrders(false, true);
    }
  }

  checkNewOrders = (prevOrderIDs, currOrderIDs) => {
    for (let i = 0; i < currOrderIDs.length; i++) {
      if (!prevOrderIDs.includes(currOrderIDs[i])) {
        this.audibleQueue++;
      }
    }

    if (this.audibleQueue > 0 && this.newOrderAudibleLoaded) {
      this.audio.play();
    }
  };

  /* Call Print - API */
  async onPrintClick(currentScreen) {
    const { skippedPickTours, createPickTour } = this.props;
    let requestBody = null;
    if (currentScreen === 1) {
      requestBody = omit(createPickTour, ["customerOrganizationLNM"]); // drop customerOrganizationLNM from request body
    } else if (currentScreen === 2) {
      requestBody = skippedPickTours[0];
      requestBody = Object.assign({}, requestBody, {
        pickTourList: filter(
          flattenDepth(
            skippedPickTours.map((item) => item.pickTourList),
            1
          ),
          ["isSkippedItemFL", "Y"]
        )
      });
    }
    if (currentScreen > 0) {
      const response = await this.props.printPickTour(requestBody);
      this.stopTimer();
      return response;
    }
  }
  /* Call /skip - API */
  updateSkipPickTour(
    pickTour,
    skippedPickTours,
    selectedOrder,
    user,
    isLastRecord = false,
    skipCompletedList,
    createPickTour
  ) {
    const skipList = flattenDepth(skipCompletedList, 1); // Make all the array elements to same level
    const currentSelectedSkipList =
      pickTour && pickTour.pickTourList && filter(pickTour.pickTourList, ["isSkippedItemFL", "Y"]); // Pick the orders which are skipped
    if (isLastRecord && (size(skipList) > 0 || size(currentSelectedSkipList) > 0)) {
      const isContainsSkipItems =
        some(skipList, ["isSkippedItemFL", "Y"]) ||
        some(pickTour.pickTourList, ["isSkippedItemFL", "Y"]); // Check any of the orders contains skip or not based on flag - "Y"
      if (isContainsSkipItems) {
        return this.props
          .updateSkipItemsForStoreId(pickTour, flattenDepth(skipList, 1))
          .then(() => {
            const { isSkipFromSat2Sat, skippedStoreDetails } = this.props;
            // If the skip originated from a Sat 2 Sat pick tour, skip past the modal and finish the tour
            if (isSkipFromSat2Sat) {
              this.updatePickTourAfterSkipConfirmation();
            } else {
              // If any orders are available near by store show transfer store modal else no store modal
              if (skippedStoreDetails) {
                this.setState({ transferStore: true });
              } else {
                // if it returns no store show No store popup and close the existing transfer store
                this.setState({ transferStore: false, noStore: true });
              }
            }
            this.stopTimer();
          })
          .catch((error) => {
            // throw new Error(error);
          });
      } else {
        return this.props
          .updateSkipPickTour(
            pickTour,
            skippedPickTours,
            selectedOrder,
            user,
            isLastRecord,
            skipCompletedList,
            createPickTour
          )
          .then(() => this.stopTimer())
          .catch((error) => {
            if (error && error.response && error.response.status === 409) {
              this.setState(
                {
                  isItemsSelected: false,
                  transferStore: false,
                  noStore: false,
                  continueStore: false,
                  selectedProducts: null
                },
                () => this.getOrders()
              );
            }
          });
      }
    } else {
      return this.props
        .updateSkipPickTour(
          pickTour,
          skippedPickTours,
          selectedOrder,
          user,
          isLastRecord,
          skipCompletedList,
          createPickTour
        )
        .then(() => this.stopTimer())
        .catch((error) => {
          if (error && error.response && error.response.status === 409) {
            this.setState(
              {
                isItemsSelected: false,
                transferStore: false,
                noStore: false,
                continueStore: false,
                selectedProducts: null
              },
              () => this.getOrders()
            );
          }
        });
    }
  }
  onForWardManually() {
    const { skippedStoreDetails } = this.props;
    if (skippedStoreDetails) {
      return this.props
        .noStoreUpdatePickTour(skippedStoreDetails.pickObj)
        .then(() => {
          // TODO: determine whether we want to wait for the print to end before resolving this promise or not
          return this.props.printPickTour(skippedStoreDetails.pickObj).then(() => {
            this.setState({ transferStore: false });
            this.stopTimer();
          });
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 409) {
            this.setState(
              {
                isItemsSelected: false,
                transferStore: false,
                noStore: false,
                continueStore: false,
                selectedProducts: null
              },
              () => this.getOrders()
            );
          }
        });
    }
  }
  /* Close/ Open Transfer Store Modal */
  onToggleTransferStore(value) {
    this.setState({ transferStore: value });
  }
  /* Close/ Open No Store Modal */
  onToggleNoStore(value) {
    this.setState({ noStore: value });
  }
  /* Call /pick/update - API */
  updatePickTourAfterSkipConfirmation() {
    const { noRecevingStore } = this.props;
    return this.props
      .noStoreUpdatePickTour(noRecevingStore)
      .then(() => {
        this.setState({ noStore: false });
        // TODO: determine whether we want to wait for the print to end before resolving this promise or not
        return this.props.printPickTour(noRecevingStore).then(() => {
          this.stopTimer();
        });
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 409) {
          this.setState(
            {
              isItemsSelected: false,
              transferStore: false,
              noStore: false,
              continueStore: false,
              selectedProducts: null
            },
            () => this.getOrders()
          );
        }
      });
  }
  /* Call /forward - API */
  forwardOrder() {
    const { skippedStoreDetails } = this.props;
    if (skippedStoreDetails) {
      this.props
        .forwardOrder(skippedStoreDetails.pickObj)
        .then(() => {
          this.setState({ transferStore: false });
          this.stopTimer();
        })
        .catch((error) => {
          // throw new Error(error);
        });
    }
  }
  /* Close Continue Store modal */
  onCloseContinueStore() {
    this.setState({ continueStore: false });
  }
  // Call dg/update - API */
  onContinueStoreSubmit() {
    const { data } = this.props;
    const { selectedProducts } = this.state;
    const _selectedPickTour =
      size(selectedProducts) > 0 &&
      data &&
      data.orders &&
      size(data.orders) > 0 &&
      find(data.orders, (item) => {
        return (
          item.commercialSalesOrderUID === selectedProducts[0].pId &&
          item.commercialDeliveryGroupSequenceNB === selectedProducts[0].sequenceNB
        );
      });

    this.props
      .continueStoreSubmit(_selectedPickTour)
      .then(() => {
        this.setState({ continueStore: false }, () => {
          this.getOrders();
          this.stopTimer();
        });
      })
      .catch((error) => {
        // throw new Error(error);
      });
  }
  /* Show Continue Store modal */
  onContinueStore() {
    this.setState({ continueStore: true });
  }
  upDateIsSavePicktourFlag(flag) {
    this.props.upDateIsSavePicktourFlag(flag);
  }
  clearServiceErrorNotifications() {
    this.props.clearServiceErrorNotifications();
  }
  render() {
    const { transferStore, noStore, continueStore } = this.state;
    const {
      skippedStoreDetails,
      noRecevingStore,
      isLoadingRefresh,
      isLoadingOrders,
      data,
      activePickTour,
      totalCount,
      activePickTourUID,
      skippedPickTours,
      storeActiveSkipped,
      storeId,
      isServiceUnavailable,
      isSkipped,
      skippedFlag,
      removeSkippedPickTour,
      user,
      createPickTour,
      skipCompletedList,
      completedPickTourList,
      currentStep,
      isSavePickTourActive,
      isAPIInProgress,
      /* error flags */
      errors
    } = this.props;

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Fragment>
          <ContinueStore
            errors={errors}
            modalState={continueStore}
            onCloseContinueStore={this.onCloseContinueStore.bind(this)}
            onContinueStoreSubmit={this.onContinueStoreSubmit.bind(this)}
          />
          <NoStore
            errors={errors}
            modalState={noStore}
            onToggleNoStore={this.onToggleNoStore.bind(this)}
            confirmnoStore={this.updatePickTourAfterSkipConfirmation.bind(this)}
            noRecevingStore={noRecevingStore}
          />
          <TransferStore
            modalState={transferStore}
            errors={errors}
            onToggleTransferStore={this.onToggleTransferStore.bind(this)}
            forwardOrder={this.forwardOrder.bind(this)}
            skippedStoreDetails={skippedStoreDetails}
            onForWardManually={this.onForWardManually.bind(this)}
          />
          <Ordering
            id="ordering"
            isSavePickTourActive={isSavePickTourActive}
            currentStep={currentStep}
            data={data}
            activePickTour={activePickTour}
            totalCount={totalCount}
            activePickTourUID={activePickTourUID}
            skippedPickTours={skippedPickTours}
            storeActiveSkipped={storeActiveSkipped}
            storeId={storeId}
            isServiceUnavailable={isServiceUnavailable}
            isSkipped={isSkipped}
            skippedFlag={skippedFlag}
            removeSkippedPickTour={removeSkippedPickTour}
            user={user}
            createPickTour={createPickTour}
            skipCompletedList={skipCompletedList}
            completedPickTourList={completedPickTourList}
            errors={errors}
            isLoadingRefresh={isLoadingRefresh || isLoadingOrders}
            notifyItemFoundServiceError={this.props.notifyItemFoundServiceError}
            notifyConfirmSkipServiceError={this.props.notifyConfirmSkipServiceError}
            clearServiceErrorNotifications={this.props.clearServiceErrorNotifications}
            onContinueStore={this.onContinueStore.bind(this)}
            onSelectedPickTour={this.onSelectedPickTour.bind(this)}
            setResetServiceUnavialbilityWithDispatch={this.setResetServiceUnavialbilityWithDispatch.bind(
              this
            )}
            onPrintClick={this.onPrintClick.bind(this)}
            onUpdatePickTour={this.onUpdatePickTour.bind(this)}
            startPickTour={this.startPickTour.bind(this)}
            restartPickTour={this.restartPickTour.bind(this)}
            constructActivePickTour={this.constructActivePickTour.bind(this)}
            getOrders={this.getOrders.bind(this)}
            updateSkipPickTour={this.updateSkipPickTour.bind(this)}
            setResetServiceUnavialbility={this.setResetServiceUnavialbility.bind(this)}
            upDateIsSavePicktourFlag={this.upDateIsSavePicktourFlag.bind(this)}
            postPickTourUrgent={this.postPickTourUrgent.bind(this)}
            onRetryFinalization={this.onRetryFinalization.bind(this)}
            isAPIInProgress={isAPIInProgress}
          />
        </Fragment>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoadingRefresh: state.inventory && state.inventory.isLoadingRefresh,
    isLoadingOrders: state.inventory && state.inventory.isLoading,
    data: state.inventory ? state.inventory.data : null,
    user: state.user ? state.user : null,
    createPickTour:
      state.inventory && state.inventory.createPickTour ? state.inventory.createPickTour : null,
    activePickTour:
      state.inventory && state.inventory.activePickTour ? state.inventory.activePickTour : null,
    activePickTourUID:
      state.inventory && state.inventory.activePickTourUID
        ? state.inventory.activePickTourUID
        : null,
    totalCount: state.inventory && state.inventory.totalCount ? state.inventory.totalCount : null,
    skippedPickTours:
      state.inventory && state.inventory.skippedPickTours ? state.inventory.skippedPickTours : [],
    storeId: state.store && state.store.storeId ? state.store.storeId : null,
    isServiceUnavailable: state && state.serviceUnavailable.isServiceUnavailable,
    isSkipped: state.inventory && state.inventory.isSkipped ? state.inventory.isSkipped : false,
    completedPickTourList: state.inventory && state.inventory.completedItemList,
    isPickTourInProgress: state.inventory && state.inventory.isPickTourInProgress,
    skipCompletedList: state.inventory && state.inventory.skipCompletedList,
    skippedStoreDetails: state.inventory && state.inventory.skippedStoreDetails,
    noRecevingStore: state.inventory && state.inventory.noRecevingStore,
    currentStep: state.inventory && state.inventory.currentStep,
    isSavePickTourActive: state.inventory.isSavePickTourActive,
    isSkipFromSat2Sat: state.inventory.isSat2Sat,
    errors: state.errors,
    isAPIInProgress: state.inventory && state.inventory.isAPIInProgress,
    storeAll: state
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrders: bindActionCreators(getOrders, dispatch),
    startPickTour: bindActionCreators(startPickTour, dispatch),
    onUpdatePickTour: bindActionCreators(onUpdatePickTour, dispatch),
    storePickTourList: bindActionCreators(storePickTourList, dispatch),
    storeSkippedPickTour: bindActionCreators(storeSkippedPickTours, dispatch),
    setResetServiceUnavialbility: bindActionCreators(setResetServiceUnavialbility, dispatch),
    skippedFlag: bindActionCreators(skippedFlag, dispatch),
    removeSkippedPickTour: bindActionCreators(removeSkippedPickTour, dispatch),
    updateSkipPickTour: bindActionCreators(updateSkipPickTour, dispatch),
    restartPickTour: bindActionCreators(restartPickTour, dispatch),
    setResetServiceUnavialbilityWithDispatch: bindActionCreators(
      setResetServiceUnavialbilityWithDispatch,
      dispatch
    ),
    printPickTour: bindActionCreators(printPickTour, dispatch),
    updateSkipItemsForStoreId: bindActionCreators(updateSkipItemsForStoreId, dispatch),
    forwardOrder: bindActionCreators(forwardOrder, dispatch),
    noStoreUpdatePickTour: bindActionCreators(noStoreUpdatePickTour, dispatch),
    continueStoreSubmit: bindActionCreators(continueStoreSubmit, dispatch),
    setByStep: bindActionCreators(setByStep, dispatch),
    upDateIsSavePicktourFlag: bindActionCreators(upDateIsSavePicktourFlag, dispatch),
    notifyItemFoundServiceError: bindActionCreators(notifyItemFoundServiceError, dispatch),
    notifyConfirmSkipServiceError: bindActionCreators(notifyConfirmSkipServiceError, dispatch),
    clearServiceErrorNotifications: bindActionCreators(clearServiceErrorNotifications, dispatch),
    autoSavePickTour: bindActionCreators(autoSavePickTour, dispatch),
    postPickTourUrgent: bindActionCreators(postPickTourUrgent, dispatch),
    retryFinalization: bindActionCreators(retryFinalization, dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderingContainer);
