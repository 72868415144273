import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import OButton from "../core-libs/components/Button";
import ADialog from "../core-libs/components/Dialog";
import ErrorMessage from "../core-libs/components/ErrorMessage";
const closeIcon = require("../assets/images/close-x.svg");
export default class ContinueStore extends Component {
  state = {
    loading: false
  };

  async onContinueStoreSubmit() {
    try {
      this.setState({ loading: true });
      await this.props.onContinueStoreSubmit();
    } finally {
      this.setState({ loading: false });
    }
  }

  constructedProps() {
    const { modalState, errors } = this.props;
    return {
      active: modalState,
      title: "",
      bodyText: (
        <div id="continueStoreDialog">
          <div className="continueTitle">
            <div className="continueText">Check The Store Printer</div>
            <div className="closeBtn">
              <span
                className="closeImg"
                onClick={() => this.props.onCloseContinueStore()}
                data-testid="continue-store-close"
              >
                <img alt="closeIcon" src={closeIcon} />
              </span>
            </div>
          </div>
          <div className="continueBody">
            <div id="bodyLine1">
              <div id="bodyLabel">
                Check the store printer for store to store transfer instructions for this order.
              </div>
            </div>
          </div>
          {errors && errors.isContinueError && (
            <div id="continueItemError">
              <ErrorMessage message="submitting" />
            </div>
          )}
          <Divider />
          <div className="continueActionsWrapper">
            <OButton
              id="proceedBtn"
              data-testid="continue-store-proceed"
              text="Ok"
              customClass="btnOk"
              onButtonClick={() => this.onContinueStoreSubmit()}
              loading={this.state.loading}
            />
            <OButton
              id="cancelBtn"
              data-testid="continue-store-cancel"
              text="Cancel"
              customClass="btnCancel"
              onButtonClick={() => this.props.onCloseContinueStore()}
            />
          </div>
        </div>
      )
    };
  }
  render() {
    return <ADialog modalProps={this.constructedProps()} />;
  }
}
