import React, { Component, Fragment } from "react";
import memoize from "memoize-one";
import { ListGroup } from "react-bootstrap";
import { filter, map, some, truncate, sumBy, size, orderBy, each, groupBy } from "lodash";
import Tooltip from "react-simple-tooltip";
import { VIEWS } from "../../components/Ordering";
import { formatDateTime } from "../../utils/azday";
import OButton from "./Button";
require("../scss/ListLayout.scss");
const _checked = require("../../assets/images/blue-check-mark.svg");
const _unchecked = require("../../assets/images/check-mark-grey.svg");
const _needBarcode = require("../../assets/images/barcode.svg");
const alertIcon = require("../../assets/images/Error.svg");
// sum the total pieces based on the  deliveryGroupDetails
const _getTotalPieces = (deliveryGroupDetails) => {
  const _sum = sumBy(deliveryGroupDetails, "orderItemQY");
  return _sum;
};
const TRANSFER = "TRANSFER";
export default class ListLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicClass: "listItem",
      scanning: false,
      results: [],
      dismissedItemErrors: {}
    };
    this.listGroupWrapperRef = React.createRef();
  }
  /* event will be triggered when the user selects the VDP/Transfer/any other orders except urgent */
  onSelectedProducts(
    commercialSalesOrderUID,
    commercialDeliveryGroupSequenceNB,
    isUPCValidationScanRequiredFL,
    deliveryMethodCD,
    customerShopNoteLTX,
    customerOrganizationLNM,
    deliverToCustomerCommercialID,
    deliveryGroupInfo
  ) {
    this.props.onSelectedProducts(
      commercialSalesOrderUID,
      commercialDeliveryGroupSequenceNB,
      isUPCValidationScanRequiredFL,
      deliveryMethodCD,
      customerShopNoteLTX,
      customerOrganizationLNM,
      deliverToCustomerCommercialID,
      deliveryGroupInfo
    );
  }
  /* event will be triggered when the user selects the  urgent */
  onSelectedPickTour(pickTour) {
    this.props.onSelectedPickTour(pickTour);
  }
  /* SAT2SAT: Determine order/pick tour type */
  getOrderType(item) {
    // Determine if order of pick tour
    if (item.deliveryGroupDetails) {
      // Determine if Sat to Sat
      if (
        item.deliveryGroupDetails[0] &&
        item.deliveryGroupDetails[0].deliveryGroupDetailTypeCD === "SAT2SAT"
      ) {
        return "Sat to Sat";
      }
      return item.isVdp ? "VDP" : item.deliveryMethodCD;
    }
    // Determine if Sat to Sat
    if (item.pickTourList[0] && item.pickTourList[0].pickListTypeCD === "SAT2SAT") {
      return "Sat to Sat";
    }
    return item.isVdp ? "VDP" : item.pickTourList[0] && item.pickTourList[0].deliveryMethodCD;
  }
  /* get the shipment color based on the delivery method */
  getClassNameByOrderType(orderType) {
    switch (orderType) {
      case "Walk In":
        return "pickup";
      case "SHIP":
        return "ship";
      case "VDP":
        return "vdp";
      case "STORE":
        return "store";
      case TRANSFER:
        return "transfer";
      default:
        return "deliver";
    }
  }
  /* arrange the orders based on the delivery method 1. Transfer 2. Urgent 3. Normal 4. VDP orders */
  constructOrders = memoize((orders, pickTours) => {
    let constructedOrders = [];
    if (size(pickTours) > 0) {
      // Urgent orders
      constructedOrders = [...filter(pickTours, (item) => item.assignedPickerEmployeeID === "")];
    }
    if (orders) {
      constructedOrders = [
        ...constructedOrders,
        // Normal orders:
        ...filter(orders, (item) => item.deliveryMethodCD !== TRANSFER),
        // Transfer orders:
        ...filter(orders, (item) => item.deliveryMethodCD === TRANSFER)
      ];
    }
    return constructedOrders;
  });

  componentDidMount() {
    /* setting the scroll vertically based on the number of orders */
    let _width = this.listGroupWrapperRef.current.offsetWidth;
    if (_width > 481) {
      this.setState({ dynamicClass: "listItemWithScroll" });
    }
  }
  /* sorting the orders based on the createTS property */
  sortOrders = memoize((orders) => {
    // extract the orders which are not the VDP and sort them according to estimatedDeliveryTS in ascending manner
    let _normalOrders = orderBy(
      filter(orders, (item) => {
        return (
          // SAT2SAT orders have a deliveryMethodCD of "TRANSFER" but should be mixed in with normal orders
          (item.deliveryMethodCD !== TRANSFER ||
            item.deliveryGroupDetails.some(
              (deliveryGroup) => deliveryGroup.deliveryGroupDetailTypeCD === "SAT2SAT"
            )) &&
          item.estimatedDeliveryTS &&
          (!item.finalizationErrorCD || item.finalizationErrorCD === 0) &&
          !some(
            item.deliveryGroupDetails,
            (detail) =>
              detail.itemFulfillmentMethodCD === "VDPFLFIL" ||
              detail.itemFulfillmentMethodCD === "VDP"
          )
        );
      }),
      ["createTS"],
      ["asc"]
    );
    if (some(orders, (item) => item.pickTourUID)) {
      const _lastCreateList = orderBy(
        filter(orders, (item) => {
          return !item.deliveryMethodCD && item.lastMaintainTS;
        }),
        ["lastMaintainTS"],
        ["desc"]
      );
      // get only VDP orders
      let _vdpOrders = orderBy(
        filter(
          orders,
          (item) =>
            item.estimatedDeliveryTS &&
            (!item.finalizationErrorCD || item.finalizationErrorCD === 0) &&
            some(
              item.deliveryGroupDetails,
              (detail) =>
                detail.itemFulfillmentMethodCD === "VDPFLFIL" ||
                detail.itemFulfillmentMethodCD === "VDP"
            )
        ),
        ["createTS"],
        ["asc"]
      );
      // set VDP true property for all the delivery method VDP or VDPFLFIL
      each(_vdpOrders, (vdpOrder) => {
        vdpOrder.isVdp = true;
      });
      let _transferOrders = orderBy(
        orders.filter(
          (item) =>
            item.deliveryMethodCD === TRANSFER &&
            !item.deliveryGroupDetails.some(
              (deliveryGroup) => deliveryGroup.deliveryGroupDetailTypeCD === "SAT2SAT"
            ) &&
            (!item.finalizationErrorCD || item.finalizationErrorCD === 0)
        ),
        ["createTS"],
        ["asc"]
      );
      let _finalizationOrders = orderBy(
        filter(
          orders,
          (item) => item.finalizationErrorCD !== undefined && item.finalizationErrorCD !== 0
        ),
        ["createTS"],
        ["asc"]
      );
      return _finalizationOrders
        .concat(_transferOrders)
        .concat(_lastCreateList)
        .concat(_normalOrders)
        .concat(_vdpOrders);
    } else {
      // sort only VDP orders
      let _vdpOrders = orderBy(
        filter(
          orders,
          (item) =>
            item.estimatedDeliveryTS &&
            (!item.finalizationErrorCD || item.finalizationErrorCD === 0) &&
            some(
              item.deliveryGroupDetails,
              (detail) =>
                detail.itemFulfillmentMethodCD === "VDPFLFIL" ||
                detail.itemFulfillmentMethodCD === "VDP"
            )
        ),
        ["createTS"],
        ["asc"]
      );
      // set VDP true property for all the delivery method VDP or VDPFLFIL
      each(_vdpOrders, (vdpOrder) => {
        vdpOrder.isVdp = true;
      });
      let _transferOrders = orderBy(
        orders.filter(
          (item) =>
            item.deliveryMethodCD === TRANSFER &&
            !item.deliveryGroupDetails.some(
              (deliveryGroup) => deliveryGroup.deliveryGroupDetailTypeCD === "SAT2SAT"
            ) &&
            (!item.finalizationErrorCD || item.finalizationErrorCD === 0)
        ),
        ["createTS"],
        ["asc"]
      );
      let _finalizationOrders = orderBy(
        filter(
          orders,
          (item) => item.finalizationErrorCD !== undefined && item.finalizationErrorCD !== 0
        ),
        ["createTS"],
        ["asc"]
      );
      return _finalizationOrders.concat(_transferOrders).concat(_normalOrders).concat(_vdpOrders);
    }
  });

  _scan() {
    this.setState({ scanning: !this.state.scanning });
  }

  _onDetected(result) {
    this.setState({ results: this.state.results.concat([result]) });
  }
  dismissItemError = (item) => {
    this.setState((state) => ({
      ...state,
      dismissedItemErrors: {
        ...state.dismissedItemErrors,
        [item.commercialSalesOrderUID]: true
      }
    }));
  };
  getActionButtonByErrorCode(item) {
    const isRetry = item && item.retryEnabled;
    return (
      <div id="actionButtonForFinalization">
        <div>
          <div className="customerNameLabel">Customer Name:</div>
          <div
            className={
              isRetry ? "custmerName actionItemName" : "custmerNameAutoWidth actionItemName"
            }
          >
            {item.customerOrganizationLNM}
          </div>
        </div>
        {isRetry && (
          <OButton
            id="btnFinalizationActionTryAgai"
            text="Try Again"
            customClass="btnFinalization"
            onButtonClick={() => this.props.onRetryFinalization(item)}
            data-testid="list-layout-try-again"
          />
        )}
      </div>
    );
  }

  render() {
    const {
      orders,
      pickTours,
      selectedProducts,
      selectedPickTour,
      inPickProcess,
      viewType
    } = this.props;
    const { dynamicClass } = this.state;
    const filteredOrders = this.constructOrders(orders, pickTours);
    return (
      <div ref={this.listGroupWrapperRef}>
        <ListGroup id="listGroup" componentClass="ul">
          {size(filteredOrders) > 0 &&
            map(this.sortOrders(filteredOrders), (item, index) => {
              let errorMessage = item.finalizationErrorMsg;
              let errorMessageCode = item.finalizationErrorCD;
              // flag to check the order is selected or not based on the state and the item
              let _isSelected = item.pickTourUID
                ? selectedPickTour && selectedPickTour.pickTourUID === item.pickTourUID
                : some(
                    selectedProducts,
                    (selectedItem) =>
                      selectedItem.pId === item.commercialSalesOrderUID &&
                      selectedItem.sequenceNB === item.commercialDeliveryGroupSequenceNB
                  );
              const _selectedClass = _isSelected ? "selectedItem" : "";
              // make order with red border if it is a Urgent order(picktour order) r transfer order
              const _redBorderClass =
                item.pickTourUID || this.getOrderType(item) === TRANSFER ? "redBorder" : "";
              let custNameDecor = item.customerOrganizationLNM
                ? item.customerOrganizationLNM.split(",").length === 1
                  ? "custNameTitleDecoration"
                  : ""
                : "";
              return (
                <Fragment key={`${index}.listItem`}>
                  {errorMessageCode !== undefined &&
                    errorMessageCode !== 0 &&
                    !this.state.dismissedItemErrors[item.commercialSalesOrderUID] && (
                      <li
                        key={`${index}.listItem`}
                        className={`list-group-item listItem finalizationError`}
                      >
                        <div className="finalizationErrorWrapper">
                          <div className="finalizationErrorIcon">
                            <img alt="alertIcon" src={alertIcon} />
                          </div>
                          <div className="finalizationErrorMsg">{errorMessage}</div>
                        </div>
                        {this.getActionButtonByErrorCode(item)}
                        {/* <button
                          className="finalizationErrorCloseButton"
                          onClick={() => this.dismissItemError(item)}
                        >
                          ×
                        </button> */}
                      </li>
                    )}
                  {(!errorMessageCode || errorMessageCode === 0) && (
                    <li
                      key={`${index}.listItem`}
                      onClick={
                        item.pickTourUID
                          ? this.onSelectedPickTour.bind(this, item)
                          : this.onSelectedProducts.bind(
                              this,
                              item.commercialSalesOrderUID,
                              item.commercialDeliveryGroupSequenceNB,
                              item.isUPCValidationScanRequiredFL,
                              item.deliveryMethodCD,
                              item.customerShopNoteLTX,
                              item.customerOrganizationLNM,
                              item.deliverToCustomerCommercialID,
                              item.deliveryGroupDetails
                            )
                      }
                      className={`list-group-item ${dynamicClass} ${_selectedClass} ${_redBorderClass}`}
                    >
                      <div className="itemWrapper">
                        <div>
                          {item.pickTourUID ? (
                            <span className="urgent dangerTitle">Urgent</span>
                          ) : (
                            <span className="dateTime">{formatDateTime(item.createTS)}</span>
                          )}
                        </div>
                        <div className="checkCircle-wrapper">
                          <div className="float-left">
                            <span className="checkCircle">
                              {!_isSelected && (
                                <img alt="checkArrow" src={_unchecked} id="CheckArrow" />
                              )}
                              {_isSelected && (
                                <img alt="selectCheck" src={_checked} id="SelectedCheckArrow" />
                              )}
                            </span>
                          </div>
                          {item.pickTourUID ? (
                            <div className={viewType === VIEWS.DESKTOP ? "w-50" : "w-60"}>
                              <ul className="organinzationMultiple">
                                {!item.pickTourList.some(
                                  (pickTourListItem) =>
                                    pickTourListItem.deliveryMethodCD === TRANSFER
                                ) && item.customerOrganizationLNM
                                  ? item.customerOrganizationLNM
                                      .split(",")
                                      .map((custOrg, index) => (
                                        <Tooltip
                                          key={index}
                                          padding={5}
                                          placement="bottom"
                                          content={item.customerOrganizationLNM}
                                          customCss={`& > div {width: 100% !important; & { div:nth-child(1) { word-break: break-all; font-size: 12px; } } }`}
                                        >
                                          <li className={`custNameTitle ${custNameDecor}`}>
                                            {truncate(custOrg, {
                                              length: 20,
                                              omission: "..."
                                            })}
                                          </li>
                                        </Tooltip>
                                      ))
                                  : ""}
                                {item.pickTourList.some(
                                  (pickTourListItem) =>
                                    pickTourListItem.deliveryMethodCD === TRANSFER &&
                                    pickTourListItem.pickListTypeCD !== "SAT2SAT"
                                ) && (
                                  <span className="custNameTitle">
                                    STORE #:{" "}
                                    {item.invoiceStoreID
                                      ? item.invoiceStoreID.replace(/^0+/, "")
                                      : ""}
                                  </span>
                                )}
                                {item.pickTourList.some(
                                  (pickTourListItem) =>
                                    pickTourListItem.deliveryMethodCD === TRANSFER &&
                                    pickTourListItem.pickListTypeCD === "SAT2SAT"
                                ) &&
                                  item.pickTourList.map((pickTourListItem, index) => (
                                    <Tooltip
                                      key={index}
                                      padding={5}
                                      placement="bottom"
                                      content={`STORE #: ${
                                        pickTourListItem.invoiceStoreID
                                          ? pickTourListItem.invoiceStoreID.replace(/^0+/, "")
                                          : ""
                                      }`}
                                      customCss={`& > div {width: 100% !important; & { div:nth-child(1) { word-break: break-all; font-size: 12px; } } }`}
                                    >
                                      <li className={`custNameTitle ${custNameDecor}`}>
                                        STORE #:{" "}
                                        {pickTourListItem.invoiceStoreID
                                          ? pickTourListItem.invoiceStoreID.replace(/^0+/, "")
                                          : ""}
                                      </li>
                                    </Tooltip>
                                  ))}
                              </ul>
                            </div>
                          ) : (
                            <Tooltip
                              padding={5}
                              placement="bottom"
                              content={item.customerOrganizationLNM}
                              customCss={`& > div {width: 100% !important; & { div:nth-child(1) { word-break: break-all; font-size: 12px; } } }`}
                            >
                              {item.deliveryMethodCD !== TRANSFER && (
                                <span className="custNameTitle">
                                  {truncate(item.customerOrganizationLNM, {
                                    length: 18,
                                    omission: "..."
                                  })}
                                </span>
                              )}
                              {item.deliveryMethodCD === TRANSFER && (
                                <span className="custNameTitle">
                                  STORE #:{" "}
                                  {item.invoiceStoreID
                                    ? item.invoiceStoreID.replace(/^0+/, "")
                                    : ""}
                                </span>
                              )}
                            </Tooltip>
                          )}
                          <div className={item.pickTourUID ? "priceWrapper w-100" : "priceWrapper"}>
                            <div id="needBarcode">
                              {item.isUPCValidationScanRequiredFL &&
                                item.isUPCValidationScanRequiredFL === "1" && (
                                  <img src={_needBarcode} alt="BarCode" />
                                )}
                            </div>
                            <div className="totalPieces">
                              Total Pieces:{" "}
                              <span className="totalPiecesCount">
                                {item.deliveryGroupDetails
                                  ? _getTotalPieces(item.deliveryGroupDetails)
                                  : _getTotalPieces(item.pickTourList)}
                              </span>
                            </div>
                            <div
                              className={`${this.getClassNameByOrderType(
                                this.getOrderType(item)
                              )} deliveryMethod`}
                            >
                              {this.getOrderType(item)}
                              {/* Store to Store Transfer */}
                            </div>
                            <div className="pickLocations">
                              Pick Locations:{" "}
                              <span className="pickLocationsCount">
                                {item.deliveryGroupDetails
                                  ? size(item.deliveryGroupDetails)
                                  : Object.keys(
                                      groupBy(
                                        item.pickTourList,
                                        (pickTourItem) => pickTourItem.partNumberID
                                      )
                                    ).length}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  )}
                </Fragment>
              );
            })}
        </ListGroup>
      </div>
    );
  }
}
