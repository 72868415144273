const ENV = window.env;

export const GET_ORDERS = `${ENV.BASE_URL}/commercial/shop/v1/dg/`;
export const POST_PICK_TOUR = `${ENV.BASE_URL}/commercial/shop/v1/pick/create`;
export const POST_UPDATE_PICK_TOUR = `${ENV.BASE_URL}/commercial/shop/v1/pick/item/update`;
export const GET_STORES = `${ENV.BASE_URL}/enterprise/store/v1/proximity-request`;
export const POST_TIMEOUT = `${ENV.BASE_URL}/commercial/shop/v1/pick/timeout`;
export const POST_UPDATE_PICK_TOUR_ALL = `${ENV.BASE_URL}/commercial/shop/v1/pick/update`;
export const PRINT_PICKTOUR = `${ENV.BASE_URL}/commercial/shop/v1/pick/print`;
export const SKIP_URL_STORE_ID = `${ENV.BASE_URL}/commercial/shop/v1/pick/skip`;
export const FORWARD_URL = `${ENV.BASE_URL}/commercial/shop/v1/dg/forward`;
export const CONTINUE_STORE_URL = `${ENV.BASE_URL}/commercial/shop/v1/dg/update`;
export const RETRY_URL = `${ENV.BASE_URL}/commercial/shop/v1/dg/retry`;
export const GET_EMPLOYEE_DATA_PREFIX = `${ENV.BASE_URL}/commercial/shop/v1/user`;
export const GET_EMPLOYEE_DATA_SUFFIX = `/metadata`;
