import React, { Component } from "react";
import Divider from "@material-ui/core/Divider";
import OButton from "../core-libs/components/Button";
import ADialog from "../core-libs/components/Dialog";
const closeIcon = require("../assets/images/close-x.svg");
export default class ItemScanningRequired extends Component {
  onResetItemScanningRequired(value) {
    if (this.props.onResetItemScanningRequired) {
      this.props.onResetItemScanningRequired(value);
    }
  }
  constructedProps() {
    const { isItemScanningRequired } = this.props;
    return {
      active: isItemScanningRequired,
      title: "",
      bodyText: (
        <div id="itemScanningRequiredDialog">
          <div className="itemScanTitle">
            <div className="itemScanText">Item Scanning Required</div>
            <div className="closeBtn">
              <span
                className="closeImg"
                onClick={this.onResetItemScanningRequired.bind(this, false)}
              >
                <img alt="closeIcon" src={closeIcon} />
              </span>
            </div>
          </div>
          <div className="itemScanningBodyText">This pick tour requires item validation.</div>
          <Divider />
          <div className="itemScanActionsWrapper">
            <OButton
              onButtonClick={this.onResetItemScanningRequired.bind(this, true)}
              id="proceedBtn"
              text="Proceed"
              customClass="proceedButtonItemScanModal"
              data-testid="item-scanning-proceed"
            />
            <OButton
              onButtonClick={this.onResetItemScanningRequired.bind(this, false)}
              id="cancelBtn"
              text="Cancel"
              customClass="cancelButtonItemScanModal"
              data-testid="item-scanning-cancel"
            />
          </div>
        </div>
      )
    };
  }
  render() {
    return <ADialog modalProps={this.constructedProps()} />;
  }
}
