const { env } = window;

export const ENVS = {
  dev: "dev",
  stagingInternal: "stinternal",
  stagingExternal: "stexternal",
  qa: "qa",
  beta: "beta",
  stagingBeta: "st-beta",
  prod: "prod"
};

export const isStagingInternal = () => env.ENV === ENVS.stagingInternal;
export const isStagingExternal = () => env.ENV === ENVS.stagingExternal;
export const isStaging = () => isStagingInternal() || isStagingExternal();
export const isDev = () => env.ENV === ENVS.dev;
export const isBeta = () => env.ENV === ENVS.beta;
export const isStagingBeta = () => env.ENV === ENVS.stagingBeta;
export const isProd = () => env.ENV === ENVS.prod;
