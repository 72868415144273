/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 *
 * @flow
 */
import * as React from "react";
import AZOauth from "@az/oauth";

const env = window.env;

const config = {
  clientId: env.COMMERCIAL_OAUTH_CLIENT_ID,
  redirectUrl: env.HOST_URL,
  scopes: ["az_internal_spa"],
  serviceConfiguration: {
    authorizationEndpoint: env.GATEWAY_BASE_URL + "authorize",
    tokenEndpoint: env.GATEWAY_BASE_URL + "token",
    revocationEndpoint: env.GATEWAY_BASE_URL + "token/revoke"
  }
};

const auth = AZOauth(config, { storage: global.sessionStorage });

const AuthContext = React.createContext<$Call<typeof AZOauth, typeof config>>(auth);

type Props = {| children: React.Node |};

function AuthProvider(props: Props) {
  return <AuthContext.Provider value={auth} {...props} />;
}

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth, auth };
